import BoldText from '@/components/BoldText';
import ColorPicker from '@/components/ColorPicker';
import UploadImage from '@/components/UploadImage';
import { ImagePosition } from '@/constants/enum';
import editContactFormSlice, { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { IUpload } from '@/types/components/upload';
import { Button, Checkbox, Label, Select, Tabs, TextField } from '@shopify/polaris';
import { DeleteIcon, ImageIcon } from '@shopify/polaris-icons';
import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IContactForm } from '../../config';

const fontSizeOptions = ['10', '12', '14', '16', '18'];

const Appearance = () => {
  const dispatch = useDispatch();
  const selectedContactForm = useSelector(selectedContactFormSelector);
  const uploadImageLabelRef = useRef<HTMLDivElement | null>(null);

  const {
    formHeight,
    formWidth,
    isAddImage,
    imageUrl,
    imagePosition,
    imageWidth,
    imageHeight,
    fieldFontWeight,
    fieldFontSize,
    fieldTextColor,
    fieldBackgroundColor,
    fieldBorderColor,
    buttonFontWeight,
    buttonFontSize,
    buttonTextColor,
    buttonBackgroundColor,
    formBackgroundColor,
    headerTextColor,
    headerFontSize,
  } = selectedContactForm?.setting?.appearance || {};

  const fontWeightOptions = useMemo(
    () => [
      { value: IContactForm.FontWeight.Light, label: 'Light' },
      { value: IContactForm.FontWeight.Regular, label: 'Regular' },
      { value: IContactForm.FontWeight.Bold, label: 'Bold' },
    ],
    [],
  );

  const positionTabs = useMemo(
    () => [
      { id: ImagePosition.Left, content: 'Left' },
      { id: ImagePosition.Top, content: 'Top' },
      { id: ImagePosition.Right, content: 'Right' },
    ],
    [],
  );
  const headerFontSizeTabs = useMemo(
    () => [
      { id: IContactForm.HeaderFontSize.Small, content: 'Small' },
      { id: IContactForm.HeaderFontSize.Medium, content: 'Medium' },
      { id: IContactForm.HeaderFontSize.Large, content: 'Large' },
    ],
    [],
  );

  const handleChange = (key: keyof IParamsApi.IFormSettingAppearance) => (value: any) => {
    dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'appearance', key, value }));
  };

  const handleUploadImage = (value: IUpload) => {
    dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'appearance', key: 'imageUrl', value: value.file }));
  };

  return (
    <>
      <BoldText>Form Size</BoldText>
      <TextField
        autoComplete="off"
        id="formHeight"
        label=""
        labelHidden
        type="number"
        suffix="px"
        connectedLeft={
          <Label id="formHeight">
            <div className="appearance-label">Form Height</div>
          </Label>
        }
        step={10}
        value={(formHeight || '0') + ''}
        onChange={(value) => {
          const newValue = Math.max(+value, 0);
          handleChange('formHeight')(newValue);
        }}
      />
      <TextField
        autoComplete="off"
        id="formWidth"
        label=""
        labelHidden
        type="number"
        suffix="px"
        connectedLeft={
          <Label id="formWidth">
            <div className="appearance-label">Form Width</div>
          </Label>
        }
        step={10}
        value={(formWidth || '0') + ''}
        onChange={(value) => {
          const newValue = Math.max(+value, 0);
          handleChange('formWidth')(newValue);
        }}
      />
      <Checkbox label="Add image to form" checked={isAddImage} onChange={(checked) => handleChange('isAddImage')(checked)} />
      {isAddImage ? (
        <>
          <div>
            {imageUrl ? (
              <div style={{ display: 'flex', justifyContent: 'end', gap: '0.5rem', marginBottom: '0.5rem' }}>
                <Button icon={ImageIcon} onClick={() => uploadImageLabelRef.current?.click()}>
                  Change
                </Button>
                <Button icon={DeleteIcon} onClick={() => handleChange('imageUrl')('')}>
                  Delete
                </Button>
              </div>
            ) : null}
            <UploadImage
              label={<div ref={uploadImageLabelRef}></div>}
              variant="default"
              url={imageUrl}
              setFile={handleUploadImage}
              limitSize={2000000}
            />
          </div>
          <div className="d-flex align-center">
            <div className="appearance-label">Position</div>
            <div className="ml-4 w-full">
              <Tabs
                tabs={positionTabs}
                selected={positionTabs.findIndex((tab) => tab.id === imagePosition)}
                onSelect={(index) => {
                  handleChange('imagePosition')(positionTabs[index].id);
                }}
                fitted
              />
            </div>
          </div>
          {imagePosition === ImagePosition.Top ? (
            <TextField
              autoComplete="off"
              id="imageHeight"
              label=""
              labelHidden
              type="number"
              suffix="px"
              connectedLeft={
                <Label id="imageHeight">
                  <div className="appearance-label">Height</div>
                </Label>
              }
              value={(imageHeight || '0') + ''}
              onChange={(value) => {
                const newValue = Math.max(+value, 0);
                handleChange('imageHeight')(newValue);
              }}
            />
          ) : (
            <TextField
              autoComplete="off"
              id="imageWidth"
              label=""
              labelHidden
              type="number"
              suffix="px"
              connectedLeft={
                <Label id="imageWidth">
                  <div className="appearance-label">Width</div>
                </Label>
              }
              value={(imageWidth || '0') + ''}
              onChange={(value) => {
                const newValue = Math.max(+value, 0);
                handleChange('imageWidth')(newValue);
              }}
            />
          )}
        </>
      ) : null}

      <BoldText>Header Style</BoldText>

      <TextField
        autoComplete="off"
        id="headerTextColor"
        label=""
        labelHidden
        prefix={
          <ColorPicker
            id="headerTextColor"
            title="Background"
            borderRadius="0.5rem"
            defaultValue={headerTextColor}
            onBlur={(value) => {
              handleChange('headerTextColor')(value);
            }}
            hideDescription
          />
        }
        connectedLeft={
          <Label id="headerTextColor">
            <div className="appearance-label">Text Color</div>
          </Label>
        }
        value={headerTextColor}
        onChange={(value) => {
          handleChange('headerTextColor')(value);
        }}
      />

      <div className="d-flex align-center">
        <div className="appearance-label">Font size</div>
        <div className="ml-4 w-full">
          <Tabs
            tabs={headerFontSizeTabs}
            selected={headerFontSizeTabs.findIndex((tab) => tab.id === headerFontSize)}
            onSelect={(index) => {
              handleChange('headerFontSize')(headerFontSizeTabs[index].id);
            }}
            fitted
          />
        </div>
      </div>

      <BoldText>Input Field Style</BoldText>

      <div className="d-flex gap-4 align-center">
        <Label id="">
          <div className="appearance-label">Font Weight</div>
        </Label>
        <div className="w-full">
          <Select
            label=""
            labelHidden
            value={fieldFontWeight}
            options={fontWeightOptions}
            onChange={handleChange('fieldFontWeight')}
          />
        </div>
        <Select
          label=""
          labelHidden
          value={fieldFontSize}
          options={fontSizeOptions}
          onChange={(value) => handleChange('fieldFontSize')(value)}
        />
      </div>

      <TextField
        autoComplete="off"
        id="fieldTextColor"
        label=""
        labelHidden
        prefix={
          <ColorPicker
            id="fieldTextColor"
            title="Background"
            borderRadius="0.5rem"
            defaultValue={fieldTextColor}
            onBlur={(value) => {
              handleChange('fieldTextColor')(value);
            }}
            hideDescription
          />
        }
        connectedLeft={
          <Label id="fieldTextColor">
            <div className="appearance-label">Text Color</div>
          </Label>
        }
        value={fieldTextColor}
        onChange={(value) => {
          handleChange('fieldTextColor')(value);
        }}
      />

      <TextField
        autoComplete="off"
        id="fieldBackgroundColor"
        label=""
        labelHidden
        prefix={
          <ColorPicker
            id="fieldBackgroundColor"
            title="Background"
            borderRadius="0.5rem"
            defaultValue={fieldBackgroundColor}
            onBlur={(value) => {
              handleChange('fieldBackgroundColor')(value);
            }}
            hideDescription
          />
        }
        connectedLeft={
          <Label id="fieldBackgroundColor">
            <div className="appearance-label">Background</div>
          </Label>
        }
        value={fieldBackgroundColor}
        onChange={(value) => {
          handleChange('fieldBackgroundColor')(value);
        }}
      />

      <TextField
        autoComplete="off"
        id="fieldBorderColor"
        label=""
        labelHidden
        prefix={
          <ColorPicker
            id="fieldBorderColor"
            title="Background"
            borderRadius="0.5rem"
            defaultValue={fieldBorderColor}
            onBlur={(value) => {
              handleChange('fieldBorderColor')(value);
            }}
            hideDescription
          />
        }
        connectedLeft={
          <Label id="fieldBorderColor">
            <div className="appearance-label">Border</div>
          </Label>
        }
        value={fieldBorderColor}
        onChange={(value) => {
          handleChange('fieldBorderColor')(value);
        }}
      />

      <BoldText>Button Style</BoldText>

      <div className="d-flex gap-4 align-center">
        <Label id="">
          <div className="appearance-label">Font Weight</div>
        </Label>
        <div className="w-full">
          <Select
            label=""
            labelHidden
            value={buttonFontWeight}
            options={fontWeightOptions}
            onChange={handleChange('buttonFontWeight')}
          />
        </div>
        <Select
          label=""
          labelHidden
          value={buttonFontSize}
          options={fontSizeOptions}
          onChange={(value) => handleChange('buttonFontSize')(value)}
        />
      </div>

      <TextField
        autoComplete="off"
        id="buttonTextColor"
        label=""
        labelHidden
        prefix={
          <ColorPicker
            id="buttonTextColor"
            title="Background"
            borderRadius="0.5rem"
            defaultValue={buttonTextColor}
            onBlur={(value) => {
              handleChange('buttonTextColor')(value);
            }}
            hideDescription
          />
        }
        connectedLeft={
          <Label id="buttonTextColor">
            <div className="appearance-label">Button Text</div>
          </Label>
        }
        value={buttonTextColor}
        onChange={(value) => {
          handleChange('buttonTextColor')(value);
        }}
      />

      <TextField
        autoComplete="off"
        id="buttonBackgroundColor"
        label=""
        labelHidden
        prefix={
          <ColorPicker
            id="buttonBackgroundColor"
            title="Background"
            borderRadius="0.5rem"
            defaultValue={buttonBackgroundColor}
            onBlur={(value) => {
              handleChange('buttonBackgroundColor')(value);
            }}
            hideDescription
          />
        }
        connectedLeft={
          <Label id="buttonBackgroundColor">
            <div className="appearance-label">Background</div>
          </Label>
        }
        value={buttonBackgroundColor}
        onChange={(value) => {
          handleChange('buttonBackgroundColor')(value);
        }}
      />

      <BoldText>Form Background</BoldText>
      <Select label="" labelHidden value={'Color'} options={['Color']} />

      <TextField
        autoComplete="off"
        id="formBackgroundColor"
        label=""
        labelHidden
        prefix={
          <ColorPicker
            id="formBackgroundColor"
            title="Background"
            borderRadius="0.5rem"
            defaultValue={formBackgroundColor}
            onBlur={(value) => {
              handleChange('formBackgroundColor')(value);
            }}
            hideDescription
          />
        }
        connectedLeft={
          <Label id="formBackgroundColor">
            <div className="appearance-label">Background</div>
          </Label>
        }
        value={formBackgroundColor}
        onChange={(value) => {
          handleChange('formBackgroundColor')(value);
        }}
      />
    </>
  );
};

export default Appearance;
