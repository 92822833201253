export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function uniq<T>(a: Array<T>) {
  return Array.from(new Set(a));
}

export const stringify = (input: any) => {
  return JSON.stringify(input);
};

export const truncate = (str: string, length?: number) => {
  const maxLength = length ?? 40;
  return str.length > maxLength ? `${str.slice(0, maxLength - 2)}...` : str;
};
