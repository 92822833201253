import { images } from '@/asset';
import BaseTable from '@/components/BaseTable';
import BoldText from '@/components/BoldText';
import CriticalBanner from '@/components/CriticalBanner';
import Layout from '@/components/layout';
import { SortDirection } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import contactFormSlice, { contactFormDataSelector, contactFormParamsSelector } from '@/redux/slice/contactForm.slice';
import editContactFormSlice, { defaultContactFormData } from '@/redux/slice/editContactForm.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IResponseApi } from '@/types/apis/response';
import {
  Badge,
  Button,
  Card,
  Icon,
  IndexFilters,
  IndexFiltersProps,
  IndexTable,
  Modal,
  useIndexResourceState,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { AlertTriangleIcon, DeleteIcon, DuplicateIcon, EditIcon, MenuVerticalIcon } from '@shopify/polaris-icons';
import { BulkAction } from '@shopify/polaris/build/ts/src/components/BulkActions';
import { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import { IconSource, MenuGroupDescriptor, NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalCreateForm from './components/ModalCreateContactForm';
import ModalEditContactForm from './components/ModalEditContactForm';
import { ContactFormPageStyled } from './styled';
import { IParamsApi } from '@/types/apis/params';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

interface IState {
  queryValue: string;
  isChangeQueryValue: boolean;
  isOpenModalCreate: boolean;
  isOpenModalEdit: boolean;
  isOpenModalDelete: boolean;
  isBulkAction: boolean;
  selectedContactFormId?: IResponseApi.IFormData['id'];
  error: {
    table: string;
    modal: string;
  };
}

const initState: IState = {
  queryValue: '',
  isChangeQueryValue: false,
  isOpenModalCreate: false,
  isOpenModalEdit: false,
  isOpenModalDelete: false,
  isBulkAction: false,
  error: {
    table: '',
    modal: '',
  },
};

const ContactFormPage = () => {
  const { mode, setMode } = useSetIndexFiltersMode();
  const dispatch = useDispatch();
  const contactFormParams = useSelector(contactFormParamsSelector);
  const contactFormData = useSelector(contactFormDataSelector);
  const isSkip = useSelector(isSkipApiSelector);

  const { data, isLoading, isFetching } = apiCaller.useGetFormQuery(contactFormParams, { skip: isSkip });
  const [createForm, { isLoading: isLoadingCreate }] = apiCaller.useCreateFormMutation();
  const [deleteForm, { isLoading: isLoadingDelete }] = apiCaller.useDeleteFormMutation();
  const [bulkDeleteForm, { isLoading: isLoadingBulkDelete }] = apiCaller.useBulkDeleteFormMutation();
  const [bulkUpdateForm, { isLoading: isLoadingBulkUpdate }] = apiCaller.useBulkUpdateFormMutation();

  const [state, setState] = useState<IState>(initState);
  const handleChangeState = (key: keyof IState) => (value: IState[keyof IState]) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
  const handleChangeError = (key: keyof IState['error']) => (value: string) => {
    setState((prev) => ({ ...prev, error: { ...prev.error, [key]: value } }));
  };

  useEffect(() => {
    dispatch(
      contactFormSlice.actions.setContactFormData(
        (data?.data || []).map((item) => ({
          ...item,
          setting: item.setting
            ? {
                ...item.setting,
                general: {
                  ...item.setting.general,
                  fields: [...(item.setting.general.fields || [])].sort((a, b) => (a.order || 0) - (b.order || 0)),
                },
              }
            : undefined,
        })),
      ),
    );
    if (data?.meta)
      dispatch(
        contactFormSlice.actions.setContactFormParams({
          ...contactFormParams,
          page: data?.meta.currentPage,
          perPage: data?.meta.perPage,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, data?.meta, dispatch]);

  const sortOptions: IndexFiltersProps['sortOptions'] = [
    { label: 'Created At', value: 'createdAt asc', directionLabel: 'Ascending' },
    { label: 'Created At', value: 'createdAt desc', directionLabel: 'Descending' },
  ];

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(
    contactFormData
      ? contactFormData.map((item) => {
          return { ...item, id: item.id.toString() };
        })
      : [],
  );

  const promotedBulkActions: (BulkAction | MenuGroupDescriptor)[] = useMemo(
    () => [
      {
        content: 'Set as active',
        onAction: () => {
          bulkUpdateForm({ ids: selectedResources.join(','), isActive: true }).then((res) => {
            const { status, msg } = checkShowErrorInline(res);
            if (!status) {
              dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
              clearSelection();
            } else {
              handleChangeError('table')(msg);
            }
          });
        },
      },
      {
        content: 'Set as draft',
        onAction: () => {
          bulkUpdateForm({ ids: selectedResources.join(','), isActive: false }).then((res) => {
            const { status, msg } = checkShowErrorInline(res);
            if (!status) {
              dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
              clearSelection();
            } else {
              handleChangeError('table')(msg);
            }
          });
        },
      },
      {
        title: <Icon source={MenuVerticalIcon} />,
        actions: [
          {
            content: 'Delete form(s)',
            prefix: (
              <div>
                <Icon source={DeleteIcon} />
              </div>
            ),
            onAction: () => {
              setState((prev) => ({ ...prev, isOpenModalDelete: true, isBulkAction: true }));
            },
          },
          // {
          //   content: 'Duplicate form(s)',
          //   prefix: (
          //     <div>
          //       <Icon source={DuplicateIcon} />
          //     </div>
          //   ),
          //   onAction: () => {},
          // },
          // {
          //   content: 'Set schedule form(s)',
          //   prefix: (
          //     <div>
          //       <Icon source={CalendarTimeIcon} />
          //     </div>
          //   ),
          //   onAction: () => {},
          // },
        ],
      },
    ],
    [bulkUpdateForm, clearSelection, dispatch, selectedResources],
  );

  const tableConfig: {
    headings: NonEmptyArray<IndexTableHeading>;
    rowMarkup: (JSX.Element | null)[] | undefined;
  } = useMemo(() => {
    const headings: IndexTableHeading[] = [
      {
        title: 'Form Name',
      },
      {
        title: 'Form type',
      },
      {
        title: 'Status',
      },
      {
        title: 'Submitted',
      },
      {
        title: 'Submit Rate',
      },
      {
        title: 'Action',
      },
    ];
    const listAction: { icon: IconSource; onClick: (item: IResponseApi.IFormData) => void; isLoading?: boolean }[] = [
      {
        icon: EditIcon,
        onClick: (item) => {
          const selectedContactForm = {
            ...item,
            setting: item.setting || defaultContactFormData.setting,
          };
          dispatch(editContactFormSlice.actions.handleSetSelectedContactForm(selectedContactForm));
          dispatch(editContactFormSlice.actions.handleSetBackupSelectedContactForm(selectedContactForm));
          setState((prev) => ({
            ...prev,
            isOpenModalEdit: true,
            selectedContactFormId: item.id,
          }));
        },
      },
      {
        icon: DeleteIcon,
        onClick: (item) => {
          setState((prev) => ({
            ...prev,
            isOpenModalDelete: true,
            selectedContactFormId: item.id,
            isBulkAction: false,
          }));
        },
      },
      {
        icon: DuplicateIcon,
        isLoading: isLoadingCreate,
        onClick: (item) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, buttonGroups, submitRate, submitTimes, ...rest } = item;
          handleChangeState('selectedContactFormId')(id);
          const newForm = {
            ...rest,
            setting: rest.setting
              ? {
                  ...rest.setting,
                  general: {
                    ...rest.setting.general,
                    fields: (rest.setting.general.fields || []).map((item) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { id, formSettingId, ...newItem } = item;
                      return newItem;
                    }),
                  },
                }
              : undefined,
          };
          createForm(newForm).then((res) => {
            const { status, msg } = checkShowErrorInline(res);
            if (!status) {
              dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
            } else {
              handleChangeError('table')(msg);
            }
          });
        },
      },
      // {
      //   icon: CalendarTimeIcon,
      //   onClick: (item) => {},
      // },
    ];
    const rowMarkup = contactFormData?.map((item, index) => {
      return (
        <IndexTable.Row
          key={item.id}
          id={item.id.toString()}
          selected={selectedResources.includes(item.id.toString())}
          position={index}
          onClick={() => {}}
        >
          <IndexTable.Cell>{item.name}</IndexTable.Cell>
          <IndexTable.Cell>{item.type}</IndexTable.Cell>
          <IndexTable.Cell>
            <Badge tone={item.isActive ? 'success' : undefined}>{item.isActive ? 'Active' : 'Draft'}</Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>{item.submitTimes ?? 0}</IndexTable.Cell>
          <IndexTable.Cell>{(item.submitRate ?? 0).toFixed(2)}%</IndexTable.Cell>
          <IndexTable.Cell className="d-flex gap-8">
            {listAction.map((action, index) => (
              <Button
                key={index}
                variant="monochromePlain"
                loading={item.id === state.selectedContactFormId && action.isLoading}
                icon={action.icon}
                onClick={() => action.onClick(item)}
              />
            ))}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });

    return { headings: headings as NonEmptyArray<IndexTableHeading>, rowMarkup };
  }, [contactFormData, createForm, dispatch, isLoadingCreate, selectedResources, state.selectedContactFormId]);

  const handleUpdateFilter = useCallback(
    (value: IParamsApi.IGetForm) => {
      dispatch(contactFormSlice.actions.setContactFormParams(value));
    },
    [dispatch],
  );

  const changePage = (page: number) => {
    handleUpdateFilter({ ...contactFormParams, page });
  };

  const handleFiltersQueryChange = useCallback((value: string) => {
    setState((prev) => ({ ...prev, queryValue: value, isChangeQueryValue: true }));
  }, []);

  const handleOrderDirectionChange = useCallback(
    (value: SortDirection) => {
      handleUpdateFilter({ ...contactFormParams, sortDirection: value, page: 1 });
    },
    [handleUpdateFilter, contactFormParams],
  );

  const handleDeleteContactForm = () => {
    if (!state.selectedContactFormId) return;
    deleteForm({ id: state.selectedContactFormId }).then((res) => {
      const { status, msg } = checkShowErrorInline(res);
      if (!status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        handleChangeState('isOpenModalDelete')(false);
      } else {
        handleChangeError('modal')(msg);
      }
    });
  };

  const handleCloseModalDelete = () => {
    handleChangeState('isOpenModalDelete')(false);
    setState((prev) => ({ ...prev, isOpenModalCreate: false, error: { ...prev.error, delete: '' } }));
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      state.isChangeQueryValue && handleUpdateFilter({ ...contactFormParams, name: state.queryValue, page: 1 });
      handleChangeState('isChangeQueryValue')(false);
    }, 500);

    return () => clearTimeout(delayInputTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.queryValue), JSON.stringify(handleUpdateFilter), JSON.stringify(contactFormParams)]);
  return (
    <ContactFormPageStyled>
      <Layout title="Contact Form">
        <div className="button-container">
          <Button variant="primary" onClick={() => handleChangeState('isOpenModalCreate')(true)}>
            Create New Form
          </Button>
        </div>
        <CriticalBanner isNotInCard isVisible={!!state.error.table}>
          {state.error.table}
        </CriticalBanner>
        <Card padding={'0'}>
          <IndexFilters
            cancelAction={{ onAction: () => {} }}
            sortOptions={sortOptions}
            sortSelected={[`createdAt ${contactFormParams.sortDirection.toLowerCase()}`]}
            queryValue={state.queryValue}
            queryPlaceholder="Searching in all"
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={() => handleChangeState('queryValue')('')}
            onSort={(value) => {
              const direction = value[0].split(' ')[1];
              handleOrderDirectionChange(direction.toUpperCase() as SortDirection);
            }}
            tabs={[]}
            selected={0}
            filters={[]}
            appliedFilters={[]}
            onClearAll={() => {
              handleUpdateFilter({ ...contactFormParams, sortDirection: SortDirection.Desc, page: 1 });
            }}
            mode={mode}
            setMode={setMode}
            canCreateNewView={false}
          />
          <BaseTable
            style={{ padding: 0 }}
            headings={tableConfig.headings}
            isLoading={isLoading}
            itemCount={contactFormData?.length || 0}
            totalOfAllItems={data?.meta?.totalResult}
            isFetching={isFetching}
            onChangePage={changePage}
            page={contactFormParams.page}
            perPage={contactFormParams.perPage}
            rowMarkup={tableConfig.rowMarkup}
            // totalOfAllItems={data?.meta?.totalResult}
            selectable
            selectedResources={selectedResources}
            allResourcesSelected={allResourcesSelected}
            onSelectionChange={handleSelectionChange}
            promotedBulkActions={promotedBulkActions}
            totalPages={data?.meta?.totalPage}
            emptyState={
              <div className="d-flex flex-center justify-center f-wrap">
                <img src={images.emptyStateFormAnswer} alt="No data to display" style={{ transform: 'scale(0.7)' }} />
                <div className="w-full text-center">
                  <BoldText>No data to display</BoldText>
                </div>
              </div>
            }
          />
        </Card>

        <ModalCreateForm
          open={state.isOpenModalCreate}
          onClose={() => handleChangeState('isOpenModalCreate')(false)}
          onSuccess={(form) => {
            const data = { ...form, setting: form.setting || defaultContactFormData.setting };
            dispatch(editContactFormSlice.actions.handleSetBackupSelectedContactForm(data));
            dispatch(editContactFormSlice.actions.handleSetSelectedContactForm(data));
            setState((prev) => ({ ...prev, isOpenModalCreate: false, isOpenModalEdit: true }));
          }}
        />

        <ModalEditContactForm open={state.isOpenModalEdit} onClose={() => handleChangeState('isOpenModalEdit')(false)} />

        <Modal
          title={
            <div className="d-flex gap-4 remove-icon-margin">
              <Icon source={AlertTriangleIcon} />
              Warning
            </div>
          }
          open={state.isOpenModalDelete}
          onClose={handleCloseModalDelete}
          primaryAction={{
            content: 'Delete',
            // @ts-ignore
            tone: 'critical',
            onAction: () => {
              if (state.isBulkAction) {
                bulkDeleteForm({ ids: selectedResources.join(',') }).then((res) => {
                  const { status, msg } = checkShowErrorInline(res);
                  if (!status) {
                    dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
                    handleChangeState('isOpenModalDelete')(false);
                    clearSelection();
                  } else {
                    handleChangeError('modal')(msg);
                  }
                });
              } else {
                handleDeleteContactForm();
              }
            },
            loading: isLoadingDelete || isLoadingBulkDelete || isLoadingBulkUpdate,
            disabled: !!state.error.modal,
          }}
          secondaryActions={[{ content: 'Cancel', onAction: handleCloseModalDelete }]}
        >
          <Modal.Section>
            <CriticalBanner isVisible={!!state.error.modal}>{state.error.modal}</CriticalBanner>
            This action cannot be undone. The selected form(s) will be permanently deleted.
          </Modal.Section>
        </Modal>
      </Layout>
    </ContactFormPageStyled>
  );
};

export default ContactFormPage;
