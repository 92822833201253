import Required from '@/components/Required';
import { IParamsApi } from '@/types/apis/params';
import React from 'react';
import styled from 'styled-components';

export interface IAppearanceProps {
  appearance?: IParamsApi.IFormSettingAppearance;
  alwaysShowPlaceholder?: boolean;
}

const TextFieldWrapper = styled.div<IAppearanceProps>`
  position: relative;
  margin-top: 8px;
  background-color: ${(props) => props.appearance?.fieldBackgroundColor || 'transparent'};
  border-radius: 9px;

  height: 47px;
  display: flex;
  align-items: center;

  &:focus-within {
    .focus-fieldset {
      border: 2px solid;
      border-color: ${(props) => props.appearance?.fieldBorderColor || '#3b82f6'};

      legend {
        max-width: 100%;
        padding: 0 4px;
        visibility: hidden;
      }
    }

    label {
      top: 0 !important;
      font-size: 14px !important;
      color: ${(props) => props.appearance?.fieldTextColor || '#3b82f6'} !important;
      font-size: 14px;
      background-color: transparent;
      opacity: 1;
    }
  }

  &:hover fieldset {
    border-width: 2px;
  }
`;

const Input = styled.input<IAppearanceProps>`
  color: ${(props) => props.appearance?.fieldTextColor || '#e4e4e4'} !important;
  outline: none;
  padding: 12px;
  width: 100%;
  border: none;
  background: transparent;

  height: 47px;
  position: relative;
  top: -1px;
  font-weight: ${(props) => props.appearance?.fieldFontWeight} !important;
  font-size: ${(props) => (props.appearance?.fieldFontSize || 16) + 'px'} !important;

  &::placeholder {
    opacity: ${(props) => (props.alwaysShowPlaceholder ? 0.5 : 0)};
    color: ${(props) => (props.alwaysShowPlaceholder ? props.appearance?.fieldTextColor || '#e4e4e4' : '')} !important;
    font-size: 14px;
    transition: opacity 0.2s;
  }

  &:focus::placeholder {
    opacity: 0.5;
    color: ${(props) => props.appearance?.fieldTextColor || '#e4e4e4'} !important;
  }

  &:not(:placeholder-shown) + label {
    top: 0;
    font-size: 14px;
    opacity: 1;
    color: ${(props) => props.appearance?.fieldTextColor || '#3b82f6'} !important;
    font-size: 14px;
  }

  &:not(:placeholder-shown) ~ .empty-fieldset {
    visibility: hidden;
  }

  &:not(:placeholder-shown) ~ .filled-fieldset {
    visibility: visible;
    border-width: 2px;
  }
`;

const Label = styled.label<IAppearanceProps>`
  position: absolute;
  left: 9px;
  top: 50%;
  font-size: 16px;
  color: ${(props) => props.appearance?.fieldTextColor || '#6b7280'} !important;
  font-size: 14px;
  transition: all 0.2s;
  padding: 0 4px;
  transform: translateY(-50%);
  pointer-events: none;
  background-color: transparent;
  z-index: 1;
  opacity: 0.5;
`;

export const BaseFieldSet = styled.fieldset<IAppearanceProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid;
  border-color: ${(props) => props.appearance?.fieldBorderColor || '#3b82f6'};
  border-radius: 8px;
  pointer-events: none;
  margin: 0;
  padding: 0;

  legend {
    margin-left: 8px;
    font-size: 14px;
    visibility: hidden;
    max-width: 0.01px;
    padding: 0;
    white-space: nowrap;
    transition: all 0.2s;
    height: 0;
  }
`;

const EmptyFieldSet = styled(BaseFieldSet)<IAppearanceProps>`
  visibility: visible;

  &.focus-fieldset {
    border: 1px solid;
    border-color: ${(props) => props.appearance?.fieldBorderColor || '#3b82f6'};
  }
`;

const FilledFieldSet = styled(BaseFieldSet)<IAppearanceProps>`
  visibility: hidden;

  legend {
    padding: 0 4px;
    max-width: 100%;
  }
`;

interface CustomTextFieldProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  appearance?: IParamsApi.IFormSettingAppearance;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  pattern?: string;
  maxLength?: number;
}

export const CustomTextField = ({
  label,
  placeholder = '',
  value,
  onChange,
  onBlur,
  appearance,
  type,
  required,
  pattern,
  maxLength,
}: CustomTextFieldProps) => {
  return (
    <>
      <TextFieldWrapper appearance={appearance}>
        <Input
          name={label || ''}
          required={required}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          appearance={appearance}
          pattern={pattern}
          maxLength={maxLength}
          onBlur={onBlur}
          alwaysShowPlaceholder={!label}
        />
        {label && (
          <>
            <Label appearance={appearance}>
              {label}
              {required && <Required />}
            </Label>
          </>
        )}
        <EmptyFieldSet appearance={appearance} className="empty-fieldset focus-fieldset">
          {label ? <legend>{label + '-'}</legend> : null}
        </EmptyFieldSet>

        <FilledFieldSet appearance={appearance} className="filled-fieldset">
          {label ? <legend>{label + '-'}</legend> : null}
        </FilledFieldSet>
      </TextFieldWrapper>
    </>
  );
};

export default CustomTextField;
