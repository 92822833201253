export const deepObjectEqual = (obj1: any, obj2: any): boolean => {
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    // if (obj1 !== obj2) console.log(obj1, obj2);
    return obj1 === obj2;
  }

  if (Array.isArray(obj1) !== Array.isArray(obj2)) {
    // console.log('array', obj1, obj2);
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      // console.log('array length', obj1, obj2);
      return false;
    }
    // if (!obj1.every((item, index) => deepObjectEqual(item, obj2[index]))) console.log('array every', obj1, obj2);
    return obj1.every((item, index) => deepObjectEqual(item, obj2[index]));
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    // console.log('keys length', obj1, obj2);
    return false;
  }
  return keys1.every((key) => deepObjectEqual(obj1[key], obj2[key]));
};
