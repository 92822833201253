import { DynamicContentBannerStyled } from '@/components/DynamicContentBanner/styled';
import { Ga4Event } from '@/constants/enum';
import { apiCaller, letMetrixApiCaller } from '@/redux/query';
import bannerSlice, { isShowedDynamicContentBannerSelector } from '@/redux/slice/banner.slice';
import {
  isShowedFbPixelSelector,
  isShowedOderTrackingSelector,
  isShowedSyncTrackSelector,
  isSubmittedFbPixelSelector,
  isSubmittedOderTrackingSelector,
  isSubmittedSyncTrackSelector,
  sessionSlice,
} from '@/redux/slice/session.slice';
import { Banner, Button } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IDataBanner {
  id: Ga4Event;
  impressionId: Ga4Event;
  appId?: string;
  title: string;
  description: React.ReactNode;
  url: string;
}

const mockData: IDataBanner[] = [
  {
    id: Ga4Event.FbPixelClick,
    impressionId: Ga4Event.FbPixelImpression,
    appId: 'facebook-multi-pixels',
    title: 'Run Facebook ads but get no sales',
    description: (
      <p className="mb-8">
        Lost sales = bad ads tracking. Achieve expert-level tracking with Facebook Pixel and Conversion API to capture every
        signal, understand buyer behavior, and retarget ads that sell better!
      </p>
    ),
    url: 'https://apps.shopify.com/facebook-multi-pixels?utm_campaign=crs&utm_source=ot&utm_medium=homeinapp',
  },
  {
    id: Ga4Event.QuoteClick,
    impressionId: Ga4Event.QuoteImpression,
    appId: 'request-for-quote-by-omega',
    title: 'Hide price and get quote from customers',
    description: (
      <p className="mb-8">
        Effectively manage quote inquiries. Boost qualified leads and customer engagement. Auto-assign quote requests to the right
        team members.
      </p>
    ),
    url: 'https://apps.shopify.com/request-for-quote-by-omega?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
  },
  {
    id: Ga4Event.FeedClick,
    impressionId: Ga4Event.FeedImpression,
    appId: 'google-shopping-feed-pro',
    title: 'Easily syncing your products to Google Merchant Center.',
    description: <p className="mb-8">Save time & enhancing product visibility and sales.</p>,
    url: 'https://apps.shopify.com/google-shopping-feed-pro?utm_campaign=crs&utm_source=fbchat&utm_medium=homeinapp',
  },
];

// const randomIndex = (arrayLength: number) => Math.floor(Math.random() * arrayLength);

const DynamicContentBanner = () => {
  const dispatch = useDispatch();
  const [sendGA4Event] = apiCaller.useSendGA4EventMutation();
  const isShowedDynamicContentBanner = useSelector(isShowedDynamicContentBannerSelector);
  const [data, setData] = useState<IDataBanner | undefined>();
  const [isInit, setIsInit] = useState(true);
  const isSubmittedFeed = useSelector(isSubmittedSyncTrackSelector);
  const isSubmittedQuote = useSelector(isSubmittedOderTrackingSelector);
  const isShowedSyncTrack = useSelector(isShowedSyncTrackSelector);
  const isShowedOderTracking = useSelector(isShowedOderTrackingSelector);
  const isSubmittedFbPixel = useSelector(isSubmittedFbPixelSelector);
  const isShowedFbPixel = useSelector(isShowedFbPixelSelector);

  const { data: dataLemetrix } = letMetrixApiCaller.useGetListAppInstalledQuery();

  const installedApps = useMemo(() => {
    return dataLemetrix?.data?.apps || [];
  }, [dataLemetrix?.data?.apps]);

  const currentMockData = useMemo(() => {
    const remain = mockData.filter((appData) => !installedApps.some((app) => app.app_id === appData.appId));
    return remain;
  }, [installedApps]);

  useEffect(() => {
    const totalTime = isInit ? 3000 : 7000;
    const id = setInterval(() => {
      isInit && setIsInit(false);
      setData((prevData) => {
        const currentIndex = currentMockData.findIndex((item) => item.title === prevData?.title);
        const nextIndex = (currentIndex + 1) % currentMockData.length;
        return currentMockData[nextIndex];
      });
    }, totalTime);

    return () => clearInterval(id);
  }, [currentMockData, isInit]);

  const handleSendGA4Event = useCallback(
    (event: Ga4Event) => {
      const dataSubmit = {
        eventName: event,
        params: {},
      };
      if (event === Ga4Event.FbPixelClick) {
        if (!isSubmittedFeed) {
          sendGA4Event(dataSubmit);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedSyncTrack', value: true }));
        }
      } else if (event === Ga4Event.QuoteClick) {
        if (!isSubmittedQuote) {
          sendGA4Event(dataSubmit);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedOderTracking', value: true }));
        }
      } else if (event === Ga4Event.FeedClick) {
        if (!isSubmittedFbPixel) {
          sendGA4Event(dataSubmit);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedFbPixel', value: true }));
        }
      } else {
        sendGA4Event(dataSubmit);
      }
    },
    [isSubmittedFeed, sendGA4Event, dispatch, isSubmittedQuote, isSubmittedFbPixel],
  );
  useEffect(() => {
    if (isInit || !data) return;
    let currentIsShow = true;
    let currentKey = '';

    switch (data.impressionId) {
      case Ga4Event.QuoteImpression:
        currentKey = 'isShowedSyncTrack';
        currentIsShow = isShowedSyncTrack;
        break;
      case Ga4Event.FeedImpression:
        currentKey = 'isShowedOderTracking';
        currentIsShow = isShowedOderTracking;
        break;
      case Ga4Event.FbPixelImpression:
        currentKey = 'isShowedFbPixel';
        currentIsShow = isShowedFbPixel;
        break;
      default:
        break;
    }

    if (!currentIsShow) {
      dispatch(sessionSlice.actions.handleUpdateSession({ key: currentKey as any, value: true }));
      handleSendGA4Event(data.impressionId);
    }
  }, [dispatch, handleSendGA4Event, isShowedOderTracking, isShowedSyncTrack, isShowedFbPixel, currentMockData, isInit, data]);

  const handleHideBanner = () => {
    dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isShowedDynamicContentBanner', value: false }));
  };

  return isShowedDynamicContentBanner && data ? (
    <DynamicContentBannerStyled>
      <Banner title={data.title} onDismiss={handleHideBanner}>
        <div>
          {data.description}
          <Button url={data.url} target="_blank" variant="primary" onClick={() => handleSendGA4Event(data.id)}>
            Try for free
          </Button>
        </div>
      </Banner>
    </DynamicContentBannerStyled>
  ) : null;
};

export default DynamicContentBanner;
