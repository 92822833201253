import FormDynamicField from '@/pages/ContactForm/components/ModalPreview/FormDynamicField';
import { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  isSubmitted?: boolean;
  isMobileView: boolean;
  handleSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  countDownValue?: number;
}

const FormContent = ({ isSubmitted, isMobileView, handleSubmitForm, countDownValue }: IProps) => {
  const dataConfigForm = useSelector(selectedContactFormSelector);

  const generalConfig = useMemo(() => {
    return dataConfigForm?.setting?.general;
  }, [dataConfigForm]);

  const appearanceConfig = useMemo(() => {
    return dataConfigForm?.setting?.appearance;
  }, [dataConfigForm]);

  return (
    <>
      {generalConfig?.isAllowHeader && (
        <div className="form-header">
          <h2
            style={{
              fontWeight: 700,
              color: appearanceConfig?.headerTextColor || 'inherit',
              fontSize: appearanceConfig?.headerFontSize,
            }}
          >
            {generalConfig?.headerTitle}
          </h2>
          <p style={{ fontSize: appearanceConfig?.fieldFontSize, color: appearanceConfig?.headerTextColor || 'inherit' }}>
            {generalConfig?.headerContent}
          </p>
        </div>
      )}

      {isSubmitted ? (
        <p
          className="mt-16"
          style={{ fontSize: appearanceConfig?.fieldFontSize, color: appearanceConfig?.headerTextColor || 'inherit' }}
        >
          Please waiting <b>{countDownValue}</b> seconds to submit another response
        </p>
      ) : (
        <div
          className="form-content mt-16"
          style={{
            fontSize: appearanceConfig?.fieldFontSize ? appearanceConfig?.fieldFontSize + 'px' : '0.875rem',
            fontWeight: appearanceConfig?.fieldFontWeight,
            color: appearanceConfig?.fieldTextColor,
          }}
        >
          <FormDynamicField isMobile={isMobileView} handleSubmitForm={handleSubmitForm} />
        </div>
      )}
    </>
  );
};

export default FormContent;
