import { Text, TextProps } from '@shopify/polaris';
import { memo } from 'react';
type IAlignment = 'start' | 'center' | 'end' | 'justify';

const BoldText = ({
  children,
  alignment,
  variant,
}: {
  children: React.ReactNode;
  alignment?: IAlignment;
  variant?: TextProps['variant'];
}) => {
  return (
    <Text as="h6" variant={variant ?? 'headingMd'} alignment={alignment}>
      {children}
    </Text>
  );
};
export default memo(BoldText);
