import CustomTextField from '@/components/CustomTextfield';
import Required from '@/components/Required';
import { FormFieldWidth, FormSettingFieldType } from '@/constants/enum';
import { PhoneInputStyled } from '@/pages/ContactForm/components/ModalPreview/styled';
import { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { useMemo, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';

interface IPhoneInputCountry {
  countryCode: string;
  dialCode: string;
  format: string;
  iso2: string;
  name: string;
  priority: number;
}

const RadioInput = ({
  col,
  label,
  id,
  name,
  type,
  isMobile,
  isRequired,
}: {
  col: number;
  label: string;
  id: string;
  name: string;
  type: 'checkbox' | 'radio';
  isMobile: boolean;
  isRequired?: boolean;
}) => {
  return (
    <div className="d-flex gap-4 align-start h-fit" style={{ width: isMobile ? '100%' : `calc(${100 / col}%)` }}>
      <input
        id={id}
        className={`form-input-option ${type === 'radio' ? 'radio-input' : 'checkbox-input'}`}
        type={type}
        name={name}
        required={isRequired}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const OtherOption = ({
  label,
  id,
  name,
  type,
  otherOptionPlaceholder,
  appearance,
  isRequired,
}: {
  label: string;
  id: string;
  name: string;
  type: 'checkbox' | 'radio';
  otherOptionPlaceholder?: string;
  appearance?: IParamsApi.IFormSettingAppearance;
  isRequired?: boolean;
}) => {
  return (
    <div className="w-full">
      <div className="d-flex gap-4 align-start h-fit" style={{ width: '100%' }}>
        <input
          id={id}
          className={`form-input-option ${type === 'radio' ? 'radio-input' : 'checkbox-input'}`}
          type={type}
          name={name}
          required={isRequired}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      {
        <div>
          <CustomTextField label={label} placeholder={otherOptionPlaceholder} appearance={appearance} required={false} />
        </div>
      }
    </div>
  );
};

const calculateWidth = (width: string, isMobile: boolean) => {
  if (isMobile) return '100%';

  const currentWidth = width === FormFieldWidth.Half ? '50%' : '100%';

  if (currentWidth === '50%') {
    return `calc(${currentWidth} - 8px)`;
  }
  return currentWidth;
};

const renderField = (
  field: IParamsApi.IFormSettingField,
  isMobile: boolean,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  appearance?: IParamsApi.IFormSettingAppearance,
) => {
  const currentType = field.type;
  switch (currentType) {
    case FormSettingFieldType.Text:
    case FormSettingFieldType.Number:
    case FormSettingFieldType.Email:
    case FormSettingFieldType.Date:
    case FormSettingFieldType.Time:
      return (
        <CustomTextField
          label={field.label}
          placeholder={field.placeholder}
          appearance={appearance}
          type={currentType}
          required={field.isRequired}
        />
      );
    case FormSettingFieldType.Phone:
      return (
        <>
          <PhoneInputStyled
            country={'us'}
            inputProps={{
              name: field.label,
              id: field.label,
              required: field.isRequired,
              'aria-label': field.placeholder,
              placeholder: field.placeholder,
            }}
            isValid={(value, country) => {
              const dialCode = (country as IPhoneInputCountry)?.dialCode;
              const dialCodeLength = dialCode?.length ? dialCode.length : 0;
              const phoneValue = dialCodeLength ? value.slice(dialCodeLength) : '';
              if (field.isRequired && !phoneValue) {
                // setIsPhoneInputError(true);
                return 'Please enter phone number';
              }
              // setIsPhoneInputError(false);
              return true;
            }}
            appearance={appearance}
          />
        </>
      );
    case FormSettingFieldType.Paragraph:
      return field?.paragraph;
    case FormSettingFieldType.Checkbox:
      return (
        <>
          <div className="input-label">
            {field.label}
            {field.isRequired ? <Required /> : null}
          </div>
          <div
            className="d-flex flex-wrap h-fit"
            onChange={(e: React.ChangeEvent<HTMLDivElement>) => {
              if (!field.isRequired) return;

              // Lấy tất cả checkbox trong container này có name chứa field.label
              const container = e.currentTarget;
              const checkboxes = container.querySelectorAll(`input[type="checkbox"][name*="${field.label}"]`);
              let isAnyChecked = false;

              // Kiểm tra xem có checkbox nào được check không
              checkboxes.forEach((checkbox) => {
                if ((checkbox as HTMLInputElement).checked) {
                  isAnyChecked = true;
                }
              });

              // Set required cho tất cả checkbox dựa vào điều kiện
              checkboxes.forEach((checkbox) => {
                (checkbox as HTMLInputElement).required = !!field.isRequired && !isAnyChecked;
              });
            }}
          >
            {field.options?.map((option, index) => (
              <RadioInput
                key={index}
                isMobile={isMobile}
                label={option}
                id={`checkbox-group-${field.id}-${index}`}
                col={field?.optionPerLine || 2}
                name={field.label || ''}
                type="checkbox"
                isRequired={field.isRequired}
              />
            ))}
            {field.enableOtherOption ? (
              <OtherOption
                label={field.otherOptionLabel || ''}
                id={`checkbox-group-other-${field.id}`}
                name={field.label || ''}
                type="checkbox"
                otherOptionPlaceholder={field.otherOptionPlaceholder}
                appearance={appearance}
              />
            ) : null}
          </div>
        </>
      );
    case FormSettingFieldType.Radio:
      return (
        <>
          <div className="input-label">
            {field.label}
            {field.isRequired ? <Required /> : null}
          </div>
          <div className="d-flex flex-wrap h-fit">
            {field.options?.map((option, index) => (
              <RadioInput
                key={index}
                isMobile={isMobile}
                label={option}
                id={`radio-group-${field.id}-${index}`}
                col={field?.optionPerLine || 2}
                name={field.label || ''}
                type="radio"
                isRequired={field.isRequired}
              />
            ))}
            {field.enableOtherOption ? (
              <OtherOption
                label={field.otherOptionLabel || ''}
                id={`radio-group-other-${field.id}`}
                name={field.label || ''}
                type="radio"
                otherOptionPlaceholder={field.otherOptionPlaceholder}
                appearance={appearance}
              />
            ) : null}
          </div>
        </>
      );
    case FormSettingFieldType.Dropdown:
      return (
        <select
          className="form-select"
          name={field.label || ''}
          style={{
            maxHeight: '47px',
            backgroundColor: appearance?.fieldBackgroundColor || '#fff',
            color: appearance?.fieldTextColor || '#000',
            fontSize: appearance?.fieldFontSize ? appearance?.fieldFontSize + 'px' : '0.875rem',
            fontWeight: appearance?.fieldFontWeight || 'normal',
            // border: `1px solid black`,
            borderColor: appearance?.fieldBorderColor || '#ccc',
          }}
        >
          {field.options?.map((option: string, index: number) => (
            <option key={`${option}-${index}`}>{option}</option>
          ))}
        </select>
      );
    case FormSettingFieldType.Divider:
      return <div className="divider" />;
    default:
      return null;
  }
};

const FieldWrapper = ({
  field,
  isMobile,
  appearance,
}: {
  field: any;
  isMobile: boolean;
  appearance?: IParamsApi.IFormSettingAppearance;
}) => {
  const width = calculateWidth(field.width || '', isMobile);
  const [timeValue, setTimeValue] = useState('');

  return <div style={{ width }}>{renderField(field, isMobile, timeValue, setTimeValue, appearance)}</div>;
};

const FormDynamicField = ({
  isMobile,
  handleSubmitForm,
}: {
  isMobile: boolean;
  handleSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
}) => {
  const dataConfigForm = useSelector(selectedContactFormSelector);
  // const [isPhoneInputError, setIsPhoneInputError] = useState(false);

  const fieldList = useMemo(() => {
    return dataConfigForm?.setting?.general?.fields || [];
  }, [dataConfigForm?.setting?.general?.fields]);

  const appearance = useMemo(() => {
    return dataConfigForm?.setting?.appearance;
  }, [dataConfigForm?.setting?.appearance]);

  return (
    <form id="ot-chat-form" onSubmit={handleSubmitForm}>
      {fieldList.map((field, index) => (
        <FieldWrapper key={`${field?.id || ''}-${index}`} field={field} isMobile={isMobile} appearance={appearance} />
      ))}

      {(dataConfigForm?.setting?.general?.formAgreeEmailSubscription ||
        dataConfigForm?.setting?.general?.formAgreeSMSSubscription) && (
        <div className="w-full">
          {dataConfigForm?.setting?.general?.formAgreeEmailSubscription && (
            <div className="d-flex gap-4 align-start h-fit" style={{ width: '100%' }}>
              <input
                id={'formAgreeEmailSubscription'}
                className={`form-input-option checkbox-input`}
                type="checkbox"
                name={'formAgreeEmailSubscription'}
              />
              <label htmlFor={'formAgreeEmailSubscription'}>{dataConfigForm?.setting?.general?.formAgreeEmailContent}</label>
            </div>
          )}
          {dataConfigForm?.setting?.general?.formAgreeSMSSubscription && (
            <div className="d-flex gap-4 align-start h-fit" style={{ width: '100%' }}>
              <input
                id={'formAgreeSMSSubscription'}
                className={`form-input-option checkbox-input`}
                type="checkbox"
                name={'formAgreeSMSSubscription'}
              />
              <label htmlFor={'formAgreeSMSSubscription'}>{dataConfigForm?.setting?.general?.formAgreeSMSContent}</label>
            </div>
          )}
        </div>
      )}
    </form>
  );
};

export default FormDynamicField;
