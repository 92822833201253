import { KeyOfAdvance, KeyOfBaseChannel } from '@/constants';
import { Device, ICon } from '@/constants/enum';
import { sendCrispMessage } from '@/helpers';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import widgetSlice, { channelsSelectedSelector, errorSelector } from '@/redux/slice/widget.slice';
import { IChanelProps } from '@/types/components/channels';
import { IUpload } from '@/types/components/upload';
import { InlineError, TextField } from '@shopify/polaris';
import { memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import ChannelsButton from '.';
import BaseCollapsible from '../BaseCollapsible/BaseCollapsible';
import RegularText from '../RegularText';
import Switch from '../Switch';
import UploadImage from '../UploadImage';
const Whatsapp = ({ keyValue }: IChanelProps) => {
  const error = useSelector(errorSelector);
  const channel = useChannelSelected(keyValue).channel;
  const dispatch = useDispatch();
  const channels = useSelector(channelsSelectedSelector);
  const whatsappProfileImage = channels.find((item) => item.channel === keyValue)?.advance?.whatsappProfileImage;
  const handleChange = (key: KeyOfBaseChannel) => (value: string | File | boolean | Device[]) => {
    if (channel) {
      const index = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[index] = {
        ...channel,
        [key]: value,
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };
  const handleChangePhone = (value: string) => {
    handleChange('value')(value);
  };

  const handleChangeAdvance = (key: KeyOfAdvance) => (value: string | boolean | IUpload) => {
    if (channel) {
      const index = channels.findIndex((item) => item.channel === keyValue);
      const newChannels = [...channels];
      newChannels[index] = {
        ...channel,
        advance: {
          ...channel.advance,
          [key]: value,
        },
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };
  const handleUploadFile = (value: IUpload) => {
    const newChannels = [...channels];
    const index = newChannels.findIndex((item) => item.channel === keyValue);
    if (index !== -1) {
      newChannels[index] = {
        ...newChannels[index],
        advance: {
          ...newChannels[index].advance,
          whatsappProfileImage: value,
        },
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
    }
  };
  return channel ? (
    <ChannelsButton
      showAddAgent
      key={keyValue}
      keyValue={keyValue}
      tooltip="Choose country code from the dropdown menu, or enter the country code (for example, +1), and then enter your phone number without the leading zero (example, 9878934509)"
      isPhoneNumber
      prefix={
        <div>
          <PhoneInput
            inputStyle={error.icon?.includes(ICon.Whatsapp) && error.status ? { backgroundColor: '#fee9e8' } : undefined}
            placeholder="Example: +1548587534"
            country="VN"
            value={channel.value}
            onChange={(value) => {
              handleChangePhone(value);
              if (error.status && error.icon?.includes(ICon.Whatsapp)) {
                const newIconsError = [...(error.icon || [])].filter((item) => item !== ICon.Whatsapp);
                dispatch(
                  widgetSlice.actions.handleError({
                    ...error,
                    icon: newIconsError,
                  }),
                );
              }
            }}
            enableLongNumbers={18}
          />
          {error.icon?.includes(ICon.Whatsapp) && error.status ? (
            <InlineError fieldID="whatsapp-error" message={'This field is required'} />
          ) : undefined}
        </div>
      }
      expandActionSettings={
        <div>
          <div className="d-flex">
            <Switch
              isActive={!!channel.advance?.whatsappChatWidget}
              onSwitch={(value) => {
                dispatch(widgetSlice.actions.handleWindow(value ? ICon.Whatsapp : undefined));
                handleChangeAdvance('whatsappChatWidget')(value);
              }}
            />
            <div className="ml-8">
              <RegularText>Display welcome WhatsApp box</RegularText>
            </div>
          </div>
          <BaseCollapsible id="whatsapp-chat-widget" open={!!channel.advance?.whatsappChatWidget}>
            <div className="mt-8">
              <div className="mt-8">
                <TextField
                  label="Heading"
                  autoComplete="off"
                  value={channel.advance?.whatsappHeading}
                  onChange={handleChangeAdvance('whatsappHeading')}
                />
              </div>
              <div className="mt-8">
                <TextField
                  label="Description"
                  autoComplete="off"
                  value={channel.advance?.whatsappDescription}
                  onChange={handleChangeAdvance('whatsappDescription')}
                />
              </div>
              <div className="mt-8">
                <TextField
                  label="Nickname"
                  autoComplete="off"
                  value={channel.advance?.whatsappNickname}
                  onChange={handleChangeAdvance('whatsappNickname')}
                />
              </div>
              <div className="mt-8">
                <TextField
                  label="Message"
                  autoComplete="off"
                  value={channel.advance?.whatsappWelcomeMessage}
                  onChange={handleChangeAdvance('whatsappWelcomeMessage')}
                />
              </div>
              <div className="mt-8">
                <TextField
                  label="Placeholder input"
                  autoComplete="off"
                  value={channel.advance?.whatsappPlaceholder}
                  onChange={handleChangeAdvance('whatsappPlaceholder')}
                />
              </div>
            </div>

            <div className="mt-8">
              <UploadImage label="Add a profile image" url={whatsappProfileImage?.url} setFile={handleUploadFile} />
            </div>
            <p className="mt-8">
              To remove “Powered by Omega”, please{' '}
              <span
                onClick={() =>
                  sendCrispMessage(`Please remove the "Powered by Omega" branding on my Whatsapp's welcoming message box`)
                }
                style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--p-color-text-emphasis)' }}
              >
                Contact us
              </span>
            </p>
          </BaseCollapsible>
        </div>
      }
    />
  ) : null;
};
export default memo(Whatsapp);
