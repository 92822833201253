import BoldSmallText from '@/components/BoldSmallText';
import editContactFormSlice, { contactFormContentSelector } from '@/redux/slice/editContactForm.slice';
import { Button, Icon, IconSource, Modal } from '@shopify/polaris';
import {
  BlogIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DeleteIcon,
  DragHandleIcon,
  DuplicateIcon,
  LayoutFooterIcon,
  LayoutHeaderIcon,
  MegaphoneIcon,
} from '@shopify/polaris-icons';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ItemInterface, ReactSortable } from 'react-sortablejs';
import { contactFormConstants, IContactForm } from '../../config';
import PopupAddContent from './components/PopupAddContent';
import { ContentStyled } from './styled';
import { IParamsApi } from '@/types/apis/params';

interface ITabConfig {
  id: number;
  icon: IconSource;
  title: React.ReactNode;
  path?: IContactForm.Path;
  content?: React.ReactNode;
}

interface IState {
  isShowConfirmModal: boolean;
  selectedContentIndex?: number;
}

const Content = () => {
  const dispatch = useDispatch();
  const content = useSelector(contactFormContentSelector);
  const { contentMap } = contactFormConstants;
  const [state, setState] = useState<IState>({ isShowConfirmModal: false });
  const handleChangeState = (key: keyof IState) => (value: IState[keyof IState]) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const list = useMemo<ItemInterface[]>(() => {
    if (!content) return [];
    return content?.map((item, index) => {
      return {
        ...item,
        id: (item.id ?? index).toString(),
        isNew: !item.id,
      };
    });
  }, [content]);

  const handleSetList = useCallback(
    (newList: ItemInterface[]) => {
      dispatch(
        editContactFormSlice.actions.handleSetContent(
          newList.map((item) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { selected, chosen, id, isNew, ...rest } = item;
            return { ...rest, id: isNew ? undefined : +id };
          }) as unknown as IParamsApi.IFormSettingField[],
        ),
      );
    },
    [dispatch],
  );

  const tabConfig: ITabConfig[] = useMemo(() => {
    return [
      {
        id: 0,
        icon: LayoutHeaderIcon,
        title: 'Header',
        path: IContactForm.Path.ContentHeader,
      },
      {
        id: 1,
        icon: BlogIcon,
        title: 'Content',
        content: (
          <>
            <ReactSortable animation={200} list={list} setList={handleSetList} handle=".drag-icon">
              {content?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="drag-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(editContactFormSlice.actions.handleSelectContent(index));
                      dispatch(editContactFormSlice.actions.handleChangePath(contentMap[item.type].path));
                    }}
                  >
                    <div
                      className="drag-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Icon source={DragHandleIcon} />
                    </div>
                    <div className="drag-item__left">
                      <Icon source={contentMap[item.type].icon} />
                      <div>{item.label || contentMap[item.type].text}</div>
                    </div>
                    <div
                      className="drag-item__right"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Button
                        variant="monochromePlain"
                        icon={DeleteIcon}
                        onClick={() => {
                          setState({ isShowConfirmModal: true, selectedContentIndex: index });
                        }}
                      />
                      <Button
                        variant="monochromePlain"
                        icon={DuplicateIcon}
                        onClick={() => {
                          dispatch(editContactFormSlice.actions.handleDuplicateContent(index));
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </ReactSortable>
            <PopupAddContent />
          </>
        ),
      },
      {
        id: 2,
        icon: MegaphoneIcon,
        title: 'Subscription',
        path: IContactForm.Path.ContentSubscription,
      },
      {
        id: 3,
        icon: LayoutFooterIcon,
        title: 'Footer',
        path: IContactForm.Path.ContentFooter,
      },
    ];
  }, [content, contentMap, dispatch, handleSetList, list]);

  const [currentActiveTab, setCurrentActiveTab] = useState<number[]>(tabConfig.map((item) => item.id));

  const handleCollapseTab = (id: number) => {
    if (currentActiveTab.includes(id)) {
      setCurrentActiveTab(currentActiveTab.filter((item) => item !== id));
    } else {
      setCurrentActiveTab([...currentActiveTab, id]);
    }
  };

  return (
    <div id="modal-create-edit-form__content">
      <div className="tab-container">
        {tabConfig.map((item, index) => {
          const isShowContent = currentActiveTab.includes(item.id);
          return (
            <Fragment key={index}>
              <Button
                key={index}
                icon={item.icon}
                variant="tertiary"
                onClick={() => {
                  if (item.path) {
                    dispatch(editContactFormSlice.actions.handleChangePath(item.path));
                  } else if (item.content) {
                    handleCollapseTab(item.id);
                  }
                }}
              >
                {
                  (
                    <div className="d-flex justify-between">
                      <BoldSmallText>{item.title}</BoldSmallText>
                      {item.content ? <Icon source={isShowContent ? ChevronDownIcon : ChevronRightIcon} /> : null}
                    </div>
                  ) as any
                }
              </Button>
              {item.content && isShowContent ? <div className="tab-content">{item.content}</div> : null}
            </Fragment>
          );
        })}
      </div>
      <style>{ContentStyled.toString()}</style>
      <Modal
        title="Warning"
        sectioned
        open={state.isShowConfirmModal}
        onClose={() => handleChangeState('isShowConfirmModal')(false)}
        primaryAction={{
          content: 'Delete',
          onAction: () => {
            if (state.selectedContentIndex === undefined) return;
            dispatch(editContactFormSlice.actions.handleDeleteContent(state.selectedContentIndex));
            setState({ isShowConfirmModal: false });
          },
          //@ts-ignore
          tone: 'critical',
        }}
        secondaryActions={[{ content: 'Cancel', onAction: () => handleChangeState('isShowConfirmModal')(false) }]}
      >
        <div id="edit-contact-form-confirm-id">
          This action cannot be undone. The selected content(s) will be permanently deleted.
        </div>
      </Modal>
    </div>
  );
};

export default Content;
