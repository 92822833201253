import { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ThankyouPage = ({ countDownValue }: { countDownValue: number }) => {
  const dataConfigForm = useSelector(selectedContactFormSelector);

  const appearanceConfig = useMemo(() => {
    return dataConfigForm?.setting?.appearance;
  }, [dataConfigForm]);

  const afterSubmission = useMemo(() => dataConfigForm?.setting?.afterSubmission, [dataConfigForm?.setting?.afterSubmission]);
  return (
    <div className="form-header">
      <h2
        style={{
          fontWeight: 700,
          color: appearanceConfig?.headerTextColor || 'inherit',
          fontSize: appearanceConfig?.headerFontSize,
        }}
      >
        {afterSubmission?.submissionTitle}
      </h2>
      <p style={{ fontSize: appearanceConfig?.fieldFontSize, color: appearanceConfig?.headerTextColor || 'inherit' }}>
        {afterSubmission?.submissionContent}
      </p>
    </div>
  );
};

export default ThankyouPage;
