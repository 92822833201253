import { Ga4Event } from '@/constants/enum';
import { useSendGA4Event } from '@/hooks/useSendGa4Event';
import bannerSlice from '@/redux/slice/banner.slice';
import { Badge, Button, Card, Grid, Icon, Text, Tooltip } from '@shopify/polaris';
import {
  AlertTriangleIcon,
  CreditCardSecureIcon,
  PackageReturnedIcon,
  ShieldCheckMarkIcon,
  ShieldPersonIcon,
  XIcon,
} from '@shopify/polaris-icons';
import { useDispatch } from 'react-redux';

const appContents = [
  {
    id: 1,
    icon: ShieldCheckMarkIcon,
    title: 'Manage shipping expectations',
    description: 'Set clear delivery expectations to stop “Where’s my order?” inquiries before they happen.',
    button: 'Set up estimated delivery',
    url: 'https://apps.shopify.com/omega-estimated-shipping-date?utm_campaign=crs&utm_source=fbchat&utm_medium=homeexpert',
    ga4Event: Ga4Event.EstimatedClick,
  },
  {
    id: 2,
    icon: CreditCardSecureIcon,
    title: 'Reduce “Where’s my order?” tickets',
    description: 'Offer real-time updates on order status, allowing customers to self-serve for tracking inquiries.',
    button: 'Set up order tracking ',
    url: 'https://apps.shopify.com/omega-order-tracking?utm_campaign=crs&utm_source=fbchat&utm_medium=homeexpert',
    ga4Event: Ga4Event.OderTrackingClick,
  },
  {
    id: 3,
    icon: PackageReturnedIcon,
    title: 'Avoid PayPal/Stripe disputes, chargerback',
    description: 'Sync delivery proof to PayPal/ Stripe will help you save time & reduce disputes & chargebacks',
    button: 'Set up sync tracking info',
    url: 'https://apps.shopify.com/synctrack?utm_campaign=crs&utm_source=fbchat&utm_medium=homeexpert',
    ga4Event: Ga4Event.SuggestedSyncTrackClick,
  },
  {
    id: 4,
    icon: ShieldPersonIcon,
    title: 'Simplify return & exchanges process',
    description: 'Give customers a simple process for returns & exchanges, cut back-and-forth communication.',
    button: 'Set up return process',
    url: 'https://apps.shopify.com/omega-returns-drive?utm_campaign=crs&utm_source=fbchat&utm_medium=homeexpert',
    ga4Event: Ga4Event.ReturnClick,
  },
];

const OmegaSuggested = () => {
  const dispatch = useDispatch();
  const handleSendGA4Event = useSendGA4Event();

  return (
    <Card>
      <div className="d-flex flex-column gap-10">
        <div className="d-flex align-center justify-between">
          <Badge tone="info">Suggested by Omega experts</Badge>
          <div
            className="ml-8 cursor-pointer"
            onClick={() => {
              dispatch(bannerSlice.actions.handleVisibleBanner({ key: 'isVisibleOmegaSuggested', value: false }));
            }}
          >
            <Tooltip content="Dismiss">
              <Icon source={XIcon} tone="base" />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex">
          <Icon source={AlertTriangleIcon} />
          <Text variant="headingMd" as="p">
            Overwhelmed support team can lead to lost sales and decreased customer loyalty
          </Text>
        </div>
        <Text variant="bodySm" as="p">
          Equip your team with tools that reduce workload and boost efficiency.
        </Text>

        <div className="mt-8">
          <Grid>
            {appContents.map((appContent) => (
              <Grid.Cell key={appContent.id} columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <Card>
                  <div className="d-flex flex-column gap-10">
                    <div className="d-flex">
                      <Icon source={appContent.icon} />
                      <Text variant="headingSm" as="p">
                        {appContent.title}
                      </Text>
                    </div>

                    <Text variant="bodySm" as="p">
                      {appContent.description}
                    </Text>

                    <div>
                      <Button
                        onClick={() => {
                          handleSendGA4Event(appContent.ga4Event);
                          window.open(appContent.url, '_blank');
                        }}
                      >
                        {appContent.button}
                      </Button>
                    </div>
                  </div>
                </Card>
              </Grid.Cell>
            ))}
          </Grid>
        </div>
      </div>
    </Card>
  );
};

export default OmegaSuggested;
