import BoldText from '@/components/BoldText';
import { config } from '@/config';
import editContactFormSlice, { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { Checkbox, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const AfterSubmission = () => {
  const dispatch = useDispatch();
  const selectedContactForm = useSelector(selectedContactFormSelector);

  const {
    submissionContent,
    submissionTitle,
    isShowThankYouMessage,
    isCloseFormAutomatically,
    isRedirectVisitor,
    linkRedirectVisitor,
    timeAfterCloseFormAutomatically,
    timeDelayAnotherResponse,
  } = selectedContactForm.setting?.afterSubmission || {};

  const handleChange = (key: keyof IParamsApi.IFormSettingAfterSubmission) => (value: any) => {
    dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'afterSubmission', key, value }));
  };

  return (
    <>
      <div className="time-input-wrapper">
        <TextField
          connectedLeft={<div>After</div>}
          connectedRight={<div>seconds</div>}
          label="Set delay for another response"
          autoComplete="off"
          type="number"
          min={20}
          max={999}
          value={(timeDelayAnotherResponse || 20).toString()}
          onChange={(value) => {
            const number = Math.min(Math.max(Number(value), 20), 999);
            handleChange('timeDelayAnotherResponse')(number);
          }}
        />
      </div>

      <Checkbox
        label="Close form automatically"
        checked={isCloseFormAutomatically}
        onChange={handleChange('isCloseFormAutomatically')}
      />
      {isCloseFormAutomatically && (
        <div className="time-input-wrapper">
          <TextField
            connectedLeft={<div>After</div>}
            connectedRight={<div>seconds</div>}
            label=""
            labelHidden
            autoComplete="off"
            type="number"
            min={0}
            max={999}
            value={(timeAfterCloseFormAutomatically || 0).toString()}
            onChange={(value) => {
              const number = Math.min(Math.max(Number(value), 0), 999);
              handleChange('timeAfterCloseFormAutomatically')(number);
            }}
          />
        </div>
      )}

      <Checkbox label="Redirect visitor" checked={isRedirectVisitor} onChange={handleChange('isRedirectVisitor')} />
      {isRedirectVisitor && (
        <TextField
          label=""
          labelHidden
          placeholder={'https://' + (config.shop || '')}
          autoComplete="off"
          type="text"
          value={linkRedirectVisitor}
          onChange={handleChange('linkRedirectVisitor')}
        />
      )}

      <Checkbox label="Show Thank You Message" checked={isShowThankYouMessage} onChange={handleChange('isShowThankYouMessage')} />
      {isShowThankYouMessage && (
        <>
          <TextField
            autoComplete="off"
            label={<BoldText>Title</BoldText>}
            value={submissionTitle}
            onChange={handleChange('submissionTitle')}
          />
          <TextField
            autoComplete="off"
            label={<BoldText>Content</BoldText>}
            value={submissionContent}
            onChange={handleChange('submissionContent')}
          />
        </>
      )}
    </>
  );
};

export default AfterSubmission;
