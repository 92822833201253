import { AutoSelection, EmptySearchResult, Icon, Listbox, Popover, Scrollable, TextField } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import React, { useEffect, useState } from 'react';

const actionValue = '__ACTION__';
export interface ISegment {
  label: string;
  value: string;
}

const interval = 25;

interface IProps {
  activator: React.ReactElement;
  segments: ISegment[];
  selectedSegment?: string;
  setSelectedSegment?: (value?: string) => void;
  initVisibleOption?: number;
  segmentsName?: string;
  allowSearch?: boolean;
  isUncheckable?: boolean;
}

function PopoverWithListbox({
  activator,
  segments,
  selectedSegment,
  setSelectedSegment,
  initVisibleOption = 6,
  segmentsName = 'segments',
  allowSearch = false,
  isUncheckable,
}: IProps) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [showFooterAction, setShowFooterAction] = useState(false);
  const [query, setQuery] = useState('');
  const [lazyLoading, setLazyLoading] = useState(false);
  const [willLoadMoreResults, setWillLoadMoreResults] = useState(true);
  const [visibleOptionIndex, setVisibleOptionIndex] = useState(initVisibleOption);
  const [activeOptionId, setActiveOptionId] = useState(segments[0]?.value || '');
  const [filteredSegments, setFilteredSegments] = useState<(typeof segments)[number][]>([]);

  useEffect(() => {
    setShowFooterAction(segments.length > initVisibleOption);
  }, [segments.length, initVisibleOption]);

  const handleClickShowAll = () => {
    setShowFooterAction(false);
    setVisibleOptionIndex(interval);
  };

  const handleFilterSegments = (query: string) => {
    const nextFilteredSegments = segments.filter((segment) => {
      return segment.label.toLocaleLowerCase().includes(query.toLocaleLowerCase().trim());
    });

    setFilteredSegments(nextFilteredSegments);
  };

  const handleQueryChange = (query: string) => {
    setQuery(query);

    if (query.length >= 2) handleFilterSegments(query);
  };

  const handleQueryClear = () => {
    handleQueryChange('');
  };

  const handleOpenPicker = () => {
    setPickerOpen(true);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    handleQueryChange('');
  };

  const handleSegmentSelect = (value: string) => {
    if (value === actionValue) {
      return handleClickShowAll();
    }

    if (isUncheckable && value === selectedSegment) {
      setSelectedSegment?.();
    } else {
      setSelectedSegment?.(value);
    }
    handleClosePicker();
  };

  const handleActiveOptionChange = (_: string, domId: string) => {
    setActiveOptionId(domId);
  };

  /* This is just to illustrate lazy loading state vs loading state. This is an example, so we aren't fetching from GraphQL. You'd use `pageInfo.hasNextPage` from your GraphQL query data instead of this fake "willLoadMoreResults" state along with setting `first` your GraphQL query's variables to your app's default max edges limit (e.g., 250). */

  const handleLazyLoadSegments = () => {
    if (willLoadMoreResults && !showFooterAction) {
      setLazyLoading(true);

      const options = query ? filteredSegments : segments;

      setTimeout(() => {
        const remainingOptionCount = options.length - visibleOptionIndex;
        const nextVisibleOptionIndex =
          remainingOptionCount >= interval ? visibleOptionIndex + interval : visibleOptionIndex + remainingOptionCount;

        setLazyLoading(false);
        setVisibleOptionIndex(nextVisibleOptionIndex);

        if (remainingOptionCount <= interval) {
          setWillLoadMoreResults(false);
        }
      }, 1000);
    }
  };

  const listboxId = 'SearchableListboxInPopover';

  /* Your app's feature/context specific activator here */
  const renderActivator = <div onClick={pickerOpen ? handleClosePicker : handleOpenPicker}>{activator}</div>;

  const textFieldMarkup = allowSearch ? (
    <div style={{ padding: '12px' }}>
      <StopPropagation>
        <TextField
          focused={showFooterAction}
          clearButton
          labelHidden
          label={segmentsName}
          placeholder={`Search ${segmentsName}`}
          autoComplete="off"
          value={query}
          prefix={<Icon source={SearchIcon} />}
          ariaActiveDescendant={activeOptionId}
          ariaControls={listboxId}
          onChange={handleQueryChange}
          onClearButtonClick={handleQueryClear}
        />
      </StopPropagation>
    </div>
  ) : null;

  const segmentOptions = query ? filteredSegments : segments;

  const segmentList =
    segmentOptions.length > 0
      ? segmentOptions.slice(0, visibleOptionIndex).map(({ label, value }) => {
          const selected = selectedSegment === value;

          return (
            <Listbox.Option key={value} value={value} selected={selected}>
              <Listbox.TextOption selected={selected}>{label}</Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  const showAllMarkup = showFooterAction ? (
    <Listbox.Action value={actionValue}>
      <span style={{ color: 'var(--p-color-text-emphasis)' }}>
        Show all {segments.length} {segmentsName}
      </span>
    </Listbox.Action>
  ) : null;

  const lazyLoadingMarkup = lazyLoading ? (
    <Listbox.Loading accessibilityLabel={`${query ? 'Filtering' : 'Loading'} ${segmentsName}`} />
  ) : null;

  const noResultsMarkup =
    segmentOptions.length === 0 ? (
      <EmptySearchResult title="" description={`No ${segmentsName} found matching "${query}"`} />
    ) : null;

  const listboxMarkup = (
    <Listbox
      enableKeyboardControl
      autoSelection={AutoSelection.FirstSelected}
      accessibilityLabel="Search"
      customListId={listboxId}
      onSelect={handleSegmentSelect}
      onActiveOptionChange={handleActiveOptionChange}
    >
      {segmentList}
      {showAllMarkup}
      {noResultsMarkup}
      {lazyLoadingMarkup}
    </Listbox>
  );

  return (
    <Popover
      active={pickerOpen}
      activator={renderActivator}
      ariaHaspopup="listbox"
      preferredAlignment="left"
      autofocusTarget="first-node"
      onClose={handleClosePicker}
    >
      <Popover.Pane fixed>
        <div
          style={{
            alignItems: 'stretch',
            borderTop: '1px solid #DFE3E8',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {textFieldMarkup}

          <Scrollable
            shadow
            style={{
              position: 'relative',
              minWidth: '100px',
              // width: '310px',
              // height: '292px',
              padding: 'var(--p-space-200) 0',
              borderBottomLeftRadius: 'var(--p-border-radius-200)',
              borderBottomRightRadius: 'var(--p-border-radius-200)',
            }}
            onScrolledToBottom={handleLazyLoadSegments}
          >
            {listboxMarkup}
          </Scrollable>
        </div>
      </Popover.Pane>
    </Popover>
  );
}

const StopPropagation = ({ children }: React.PropsWithChildren<any>) => {
  const stopEventPropagation = (event: React.MouseEvent | React.TouchEvent) => {
    event.stopPropagation();
  };

  return (
    <div onClick={stopEventPropagation} onTouchStart={stopEventPropagation}>
      {children}
    </div>
  );
};

export default PopoverWithListbox;
