import { IconSource } from '@shopify/polaris';
import {
  BlankIcon,
  CalendarIcon,
  CheckboxIcon,
  CheckCircleIcon,
  CircleChevronDownIcon,
  ClockIcon,
  EmailIcon,
  PhoneIcon,
  ReferralCodeIcon,
  TextBlockIcon,
  TextIcon,
} from '@shopify/polaris-icons';
import AfterSubmission from './components/AfterSubmission';
import Appearance from './components/Appearance';
import Content from './components/Content';
import ContentFooter from './components/Content/ContentFooter';
import ContentHeader from './components/Content/ContentHeader';
import InputContent from './components/Content/InputContent';
import Email from './components/Email';
import Settings from './components/Settings';
import { FormSettingFieldType, FormFieldWidth } from '@/constants/enum';
import { IParamsApi } from '@/types/apis/params';
import ContentSubscription from './components/Content/ContentSubscription';

export namespace IContactForm {
  export enum Path {
    Content = '/content',
    ContentHeader = '/content/header',
    ContentFooter = '/content/footer',
    ContentSubscription = '/content/subscription',
    ContentEmail = '/content/email',
    ContentPhone = '/content/phone',
    ContentParagraph = '/content/paragraph',
    ContentDivider = '/content/divider',
    ContentText = '/content/text',
    ContentNumber = '/content/number',
    ContentDropdown = '/content/dropdown',
    ContentCheckbox = '/content/checkbox',
    ContentRadio = '/content/radio',
    ContentDate = '/content/date',
    ContentTime = '/content/time',
    Appearance = '/appearance',
    AfterSubmission = '/after-submission',
    Settings = '/settings',
    Email = '/email',
  }

  export enum HeaderFontSize {
    Small = '20.12px',
    Medium = '25.14px',
    Large = '30.16px',
  }

  export enum FontWeight {
    Light = '300',
    Regular = '400',
    Bold = 'bold',
  }
}

interface IRoute {
  title: string;
  path: IContactForm.Path;
  previousPath?: IContactForm.Path;
  element: JSX.Element;
  isRemoveWrapper?: boolean;
}

export const contactFormConstants: {
  routes: IRoute[];
  contentConfig: {
    contactInfo: FormSettingFieldType[];
    customize: FormSettingFieldType[];
    inputType: FormSettingFieldType[];
  };
  contentMap: Record<FormSettingFieldType, { text: string; path: IContactForm.Path; icon: IconSource }>;
  defaultInputContentData: Record<FormSettingFieldType, IParamsApi.IFormSettingField>;
} = {
  routes: [
    {
      title: 'Content',
      path: IContactForm.Path.Content,
      element: <Content />,
      isRemoveWrapper: true,
    },
    {
      title: 'Header',
      path: IContactForm.Path.ContentHeader,
      element: <ContentHeader />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Footer',
      path: IContactForm.Path.ContentFooter,
      element: <ContentFooter />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Subscription',
      path: IContactForm.Path.ContentSubscription,
      element: <ContentSubscription />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Email',
      path: IContactForm.Path.ContentEmail,
      element: <InputContent type={FormSettingFieldType.Email} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Phone',
      path: IContactForm.Path.ContentPhone,
      element: <InputContent type={FormSettingFieldType.Phone} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Paragraph',
      path: IContactForm.Path.ContentParagraph,
      element: <InputContent type={FormSettingFieldType.Paragraph} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Divider',
      path: IContactForm.Path.ContentDivider,
      element: <InputContent type={FormSettingFieldType.Divider} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Text',
      path: IContactForm.Path.ContentText,
      element: <InputContent type={FormSettingFieldType.Text} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Number',
      path: IContactForm.Path.ContentNumber,
      element: <InputContent type={FormSettingFieldType.Number} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Dropdown',
      path: IContactForm.Path.ContentDropdown,
      element: <InputContent type={FormSettingFieldType.Dropdown} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Checkbox',
      path: IContactForm.Path.ContentCheckbox,
      element: <InputContent type={FormSettingFieldType.Checkbox} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Radio buttons',
      path: IContactForm.Path.ContentRadio,
      element: <InputContent type={FormSettingFieldType.Radio} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Date',
      path: IContactForm.Path.ContentDate,
      element: <InputContent type={FormSettingFieldType.Date} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Time',
      path: IContactForm.Path.ContentTime,
      element: <InputContent type={FormSettingFieldType.Time} />,
      previousPath: IContactForm.Path.Content,
    },
    {
      title: 'Appearance',
      path: IContactForm.Path.Appearance,
      element: <Appearance />,
    },
    {
      title: 'After Submission',
      path: IContactForm.Path.AfterSubmission,
      element: <AfterSubmission />,
    },
    {
      title: 'Settings',
      path: IContactForm.Path.Settings,
      element: <Settings />,
      isRemoveWrapper: true,
    },
    {
      title: 'Email',
      path: IContactForm.Path.Email,
      element: <Email />,
    },
  ],
  contentConfig: {
    contactInfo: [FormSettingFieldType.Email, FormSettingFieldType.Phone],
    customize: [FormSettingFieldType.Paragraph, FormSettingFieldType.Divider],
    inputType: [
      FormSettingFieldType.Text,
      FormSettingFieldType.Number,
      FormSettingFieldType.Dropdown,
      FormSettingFieldType.Checkbox,
      FormSettingFieldType.Radio,
      FormSettingFieldType.Date,
      FormSettingFieldType.Time,
    ],
  },
  contentMap: {
    [FormSettingFieldType.Email]: { text: 'Email', path: IContactForm.Path.ContentEmail, icon: EmailIcon },
    [FormSettingFieldType.Phone]: { text: 'Phone', path: IContactForm.Path.ContentPhone, icon: PhoneIcon },
    [FormSettingFieldType.Text]: { text: 'Text', path: IContactForm.Path.ContentText, icon: TextIcon },
    [FormSettingFieldType.Number]: { text: 'Number', path: IContactForm.Path.ContentNumber, icon: ReferralCodeIcon },
    [FormSettingFieldType.Dropdown]: { text: 'Dropdown', path: IContactForm.Path.ContentDropdown, icon: CircleChevronDownIcon },
    [FormSettingFieldType.Checkbox]: { text: 'Checkbox', path: IContactForm.Path.ContentCheckbox, icon: CheckboxIcon },
    [FormSettingFieldType.Radio]: { text: 'Radio buttons', path: IContactForm.Path.ContentRadio, icon: CheckCircleIcon },
    [FormSettingFieldType.Paragraph]: { text: 'Paragraph', path: IContactForm.Path.ContentParagraph, icon: TextBlockIcon },
    [FormSettingFieldType.Divider]: { text: 'Divider', path: IContactForm.Path.Content, icon: BlankIcon },
    [FormSettingFieldType.Date]: { text: 'Date', path: IContactForm.Path.ContentDate, icon: CalendarIcon },
    [FormSettingFieldType.Time]: { text: 'Time', path: IContactForm.Path.ContentTime, icon: ClockIcon },
  },
  defaultInputContentData: {
    [FormSettingFieldType.Text]: {
      type: FormSettingFieldType.Text,
      label: 'Name',
      placeholder: 'Name',
      isRequired: true,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Number]: {
      type: FormSettingFieldType.Number,
      label: 'Age',
      placeholder: 'Age',
      isRequired: true,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Email]: {
      type: FormSettingFieldType.Email,
      label: 'Email',
      placeholder: 'Email',
      isRequired: true,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Phone]: {
      type: FormSettingFieldType.Phone,
      label: 'Phone',
      placeholder: 'Phone',
      isRequired: true,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Paragraph]: {
      type: FormSettingFieldType.Paragraph,
      paragraph: '',
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Checkbox]: {
      type: FormSettingFieldType.Checkbox,
      label: 'Checkbox',
      options: ['Option 1', 'Option 2'],
      enableOtherOption: true,
      otherOptionLabel: 'Other',
      otherOptionPlaceholder: 'Add other option',
      isRequired: true,
      optionPerLine: 2,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Dropdown]: {
      type: FormSettingFieldType.Dropdown,
      label: 'Dropdown',
      placeholder: 'Select',
      options: ['Option 1', 'Option 2'],
      isRequired: true,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Divider]: { type: FormSettingFieldType.Divider },
    [FormSettingFieldType.Radio]: {
      type: FormSettingFieldType.Radio,
      label: 'Radio',
      options: ['Option 1', 'Option 2'],
      enableOtherOption: true,
      otherOptionLabel: 'Other',
      otherOptionPlaceholder: 'Add other option',
      isRequired: true,
      optionPerLine: 2,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Date]: {
      type: FormSettingFieldType.Date,
      label: 'Date',
      placeholder: 'DD/MM/YYYY',
      isRequired: true,
      width: FormFieldWidth.Half,
    },
    [FormSettingFieldType.Time]: {
      type: FormSettingFieldType.Time,
      label: 'Time',
      placeholder: '',
      isRequired: true,
      width: FormFieldWidth.Half,
    },
  },
};

export const scrollModalEditContactFormToTop = () => {
  const modalBodyElement = document.querySelector('.Polaris-Modal__Body:has(#modal-create-edit-form)');
  modalBodyElement?.scrollTo(0, 0);
};
