import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { FormSettingFieldType } from '@/constants/enum';
import editContactFormSlice, { contactFormContentSelector } from '@/redux/slice/editContactForm.slice';
import { Button, Popover } from '@shopify/polaris';
import { PlusCircleIcon } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactFormConstants } from '../../../../config';

const PopupAddContent = () => {
  const { contentConfig, contentMap } = contactFormConstants;
  const content = useSelector(contactFormContentSelector);
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  const onAdd = useCallback(
    (key: FormSettingFieldType) => {
      const res = contentMap[key];
      if (!res) return;
      dispatch(editContactFormSlice.actions.handleAddContent(key));
      content && dispatch(editContactFormSlice.actions.handleSelectContent(content?.length));
      dispatch(editContactFormSlice.actions.handleChangePath(res.path));
    },
    [content, contentMap, dispatch],
  );

  const renderContents = ({ title, key }: { title: string; key: keyof typeof contentConfig }) => {
    return (
      <div className="d-flex flex-column">
        <BoldText>{title}</BoldText>
        {contentConfig[key].map((item, index) => {
          return (
            <Button
              key={index}
              variant="tertiary"
              fullWidth
              textAlign="start"
              icon={contentMap[item].icon}
              onClick={() => {
                setActive(false);
                onAdd(item);
              }}
            >
              {(<RegularText>{contentMap[item].text}</RegularText>) as any}
            </Button>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Popover
        preferredAlignment="right"
        activator={
          <div style={{ padding: '6px 0 6px 1.5rem' }}>
            <Button variant="plain" fullWidth textAlign="start" icon={PlusCircleIcon} onClick={() => setActive(true)}>
              Add Content
            </Button>
          </div>
        }
        active={active}
        onClose={() => {
          setActive(false);
        }}
        fixed
        sectioned
      >
        <div className="d-flex gap-8">
          <div>
            {renderContents({ title: 'Contact info', key: 'contactInfo' })}
            {renderContents({ title: 'Customize', key: 'customize' })}
          </div>
          {renderContents({ title: 'Input type', key: 'inputType' })}
        </div>
      </Popover>
    </>
  );
};

export default PopupAddContent;
