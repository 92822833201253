import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { sendCrispMessage } from '@/helpers';
import editContactFormSlice, { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { Checkbox, Link, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const ContentFooter = () => {
  const dispatch = useDispatch();
  const selectedContactForm = useSelector(selectedContactFormSelector);

  const { isFooterAddText, footerContent, footerButtonText } = selectedContactForm?.setting?.general || {};

  return (
    <>
      <Checkbox
        label={'Add text'}
        checked={isFooterAddText}
        onChange={(checked) =>
          dispatch(
            editContactFormSlice.actions.handleChangeSetting({ settingType: 'general', key: 'isFooterAddText', value: checked }),
          )
        }
      />
      <TextField
        autoComplete="off"
        label={<BoldText>Text</BoldText>}
        value={footerContent}
        onChange={(value) =>
          dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'general', key: 'footerContent', value }))
        }
      />
      <TextField
        autoComplete="off"
        label={<BoldText>Button Text</BoldText>}
        value={footerButtonText}
        onChange={(value) =>
          dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'general', key: 'footerButtonText', value }))
        }
      />
      <RegularText>
        To remove “Powered by Omega” please{' '}
        <Link
          onClick={() =>
            sendCrispMessage(`Please remove the "Powered by Omega" branding on my Messenger's welcoming message box`)
          }
        >
          Contact us
        </Link>
      </RegularText>
    </>
  );
};

export default ContentFooter;
