import { css } from 'styled-components';

export const ContentStyled = css`
  #modal-create-edit-form__content {
    .tab-container {
      display: flex;
      flex-direction: column;
      > .Polaris-Button {
        padding: 0.5rem 1.25rem;
      }
      .tab-content {
        padding: 0 1.25rem;
      }
      > .Polaris-Button {
        span.Polaris-Text--root {
          width: 100%;
        }
      }
    }
  }
`;
