import BoldText from '@/components/BoldText';
import { FormFieldWidth, FormSettingFieldType } from '@/constants/enum';
import editContactFormSlice, { contactFormSelectedContentSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { Button, Checkbox, Icon, Tabs, TextField } from '@shopify/polaris';
import { DeleteIcon, DragHandleIcon } from '@shopify/polaris-icons';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { scrollModalEditContactFormToTop } from '../../../config';

interface IProps {
  type: FormSettingFieldType;
}

const InputContent = ({ type }: IProps) => {
  const dispatch = useDispatch();
  const selectedContent = useSelector(contactFormSelectedContentSelector);

  const handleChange = useCallback(
    (key: keyof IParamsApi.IFormSettingField) => (value: IParamsApi.IFormSettingField[keyof IParamsApi.IFormSettingField]) => {
      dispatch(editContactFormSlice.actions.handleChangeSelectedContentData({ key, value }));
    },
    [dispatch],
  );

  const widthTabs = useMemo(
    () => [
      { id: FormFieldWidth.Half, content: '50%' },
      { id: FormFieldWidth.Full, content: '100%' },
    ],
    [],
  );

  const optionsPerLineTabs = useMemo(
    () => [1, 2, 3, 4, 5].map((item) => ({ id: item.toString(), content: item.toString() })),
    [],
  );

  useEffect(() => {
    scrollModalEditContactFormToTop();
  }, []);

  const mapElement = useMemo(() => {
    return {
      label: (
        <TextField
          autoComplete="off"
          label={<BoldText>Label</BoldText>}
          value={selectedContent?.label}
          onChange={handleChange('label')}
        />
      ),
      placeholder: (
        <TextField
          autoComplete="off"
          label={<BoldText>Placeholder</BoldText>}
          value={selectedContent?.placeholder}
          onChange={handleChange('placeholder')}
        />
      ),
      required: (
        <Checkbox
          label={'Required'}
          checked={selectedContent?.isRequired}
          onChange={() => {
            handleChange('isRequired')(!selectedContent?.isRequired);
          }}
        />
      ),
      width: (
        <>
          <BoldText>Width</BoldText>
          <Tabs
            tabs={widthTabs}
            selected={widthTabs.findIndex((tab) => tab.id === selectedContent?.width)}
            onSelect={(index) => handleChange('width')(widthTabs[index].id)}
            fitted
          />
        </>
      ),
      paragraph: (
        <TextField
          autoComplete="off"
          label={<BoldText>Content</BoldText>}
          value={selectedContent?.paragraph}
          onChange={handleChange('paragraph')}
        />
      ),
      options: (
        <>
          <BoldText>Options</BoldText>
          <ReactSortable
            list={selectedContent?.options?.map((item) => ({ id: item })) || []}
            setList={(newList) => {
              handleChange('options')(newList.map((item) => item.id));
            }}
            handle=".drag-icon"
          >
            {selectedContent?.options?.map((item, index, array) => (
              <div key={index} className="drag-item" style={{ background: '#fff', cursor: 'default' }}>
                <div
                  className="drag-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Icon source={DragHandleIcon} />
                </div>
                <div className="w-full">
                  <TextField
                    autoComplete="off"
                    label={''}
                    value={item}
                    onChange={(value) => {
                      handleChange('options')(
                        array?.map((option, optionIndex) => {
                          if (index === optionIndex) {
                            return value;
                          }
                          return option;
                        }) || [],
                      );
                    }}
                  />
                </div>
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChange('options')(array?.filter((_, optionIndex) => index !== optionIndex) || []);
                  }}
                >
                  <Icon source={DeleteIcon} tone="critical" />
                </div>
              </div>
            ))}
          </ReactSortable>
          <div>
            <Button onClick={() => handleChange('options')([...(selectedContent?.options || []), ''])}>Add option</Button>
          </div>
        </>
      ),
      otherOption: (
        <>
          <Checkbox
            label={'Add ‘Other’ Option'}
            checked={selectedContent?.enableOtherOption}
            onChange={() => handleChange('enableOtherOption')(!selectedContent?.enableOtherOption)}
          />
          {selectedContent?.enableOtherOption ? (
            <>
              <TextField
                autoComplete="off"
                label={'‘Other’ Label'}
                value={selectedContent?.otherOptionLabel}
                onChange={handleChange('otherOptionLabel')}
              />
              <TextField
                autoComplete="off"
                label={'‘Other’ Placeholder'}
                value={selectedContent?.otherOptionPlaceholder}
                onChange={handleChange('otherOptionPlaceholder')}
              />
            </>
          ) : null}
        </>
      ),
      optionsPerLine: (
        <>
          <BoldText>Option per line</BoldText>
          <Tabs
            tabs={optionsPerLineTabs}
            selected={optionsPerLineTabs.findIndex((tab) => tab.id === selectedContent?.optionPerLine?.toString())}
            onSelect={(index) => handleChange('optionPerLine')(+optionsPerLineTabs[index].id)}
            fitted
          />
        </>
      ),
    };
  }, [
    selectedContent?.label,
    selectedContent?.placeholder,
    selectedContent?.isRequired,
    selectedContent?.paragraph,
    selectedContent?.options,
    selectedContent?.enableOtherOption,
    selectedContent?.otherOptionLabel,
    selectedContent?.otherOptionPlaceholder,
    selectedContent?.width,
    selectedContent?.optionPerLine,
    handleChange,
    widthTabs,
    optionsPerLineTabs,
  ]);

  const content = useMemo(() => {
    switch (type) {
      case FormSettingFieldType.Text:
      case FormSettingFieldType.Number:
      case FormSettingFieldType.Email:
      case FormSettingFieldType.Phone:
        return [
          { key: 'label', element: mapElement.label },
          { key: 'placeholder', element: mapElement.placeholder },
          { key: 'required', element: mapElement.required },
          { key: 'width', element: mapElement.width },
        ];
      case FormSettingFieldType.Paragraph:
        return [
          { key: 'paragraph', element: mapElement.paragraph },
          { key: 'width', element: mapElement.width },
        ];
      case FormSettingFieldType.Checkbox:
        return [
          { key: 'label', element: mapElement.label },
          { key: 'options', element: mapElement.options },
          { key: 'otherOption', element: mapElement.otherOption },
          { key: 'required', element: mapElement.required },
          { key: 'optionsPerLine', element: mapElement.optionsPerLine },
          { key: 'width', element: mapElement.width },
        ];
      case FormSettingFieldType.Dropdown:
        return [
          { key: 'label', element: mapElement.label },
          { key: 'placeholder', element: mapElement.placeholder },
          { key: 'options', element: mapElement.options },
          { key: 'required', element: mapElement.required },
          { key: 'width', element: mapElement.width },
        ];
      case FormSettingFieldType.Radio:
        return [
          { key: 'label', element: mapElement.label },
          { key: 'options', element: mapElement.options },
          { key: 'otherOption', element: mapElement.otherOption },
          { key: 'required', element: mapElement.required },
          { key: 'optionsPerLine', element: mapElement.optionsPerLine },
          { key: 'width', element: mapElement.width },
        ];
      case FormSettingFieldType.Date:
      case FormSettingFieldType.Time:
        return [
          { key: 'label', element: mapElement.label },
          { key: 'required', element: mapElement.required },
          { key: 'width', element: mapElement.width },
        ];
      default:
        return [];
    }
  }, [
    mapElement.label,
    mapElement.options,
    mapElement.optionsPerLine,
    mapElement.otherOption,
    mapElement.paragraph,
    mapElement.placeholder,
    mapElement.required,
    mapElement.width,
    type,
  ]);

  return (
    <>
      {content.map(({ key, element }) => (
        <Fragment key={`${type}-${key}`}>{element}</Fragment>
      ))}
    </>
  );
};

export default InputContent;
