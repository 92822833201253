import { Device, FormSettingDevice } from '@/constants/enum';
import FormContent from '@/pages/ContactForm/components/ModalPreview/FormContent';
import { ModalPreviewStyled } from '@/pages/ContactForm/components/ModalPreview/styled';
import ThankyouPage from '@/pages/ContactForm/components/ThankyouPage';
import { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

interface IProps {
  previewMode: Device;
}

const ModalPreviewForm = ({ previewMode }: IProps) => {
  const dataConfigForm = useSelector(selectedContactFormSelector);
  const isMobileTiny = useMediaQuery({ maxWidth: 475 });

  const [headerImageHeight, setHeaderImageHeight] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedTimes, setSubmittedTimes] = useState(0);
  const [latestSubmittedTime, setLatestSubmittedTime] = useState(0);
  const [countDownValue, setCountDownValue] = useState(0);
  const [isShowThankyouPage, setIsShowThankyouPage] = useState(false);

  const generalConfig = useMemo(() => {
    return dataConfigForm?.setting?.general;
  }, [dataConfigForm]);

  const appearanceConfig = useMemo(() => {
    return dataConfigForm?.setting?.appearance;
  }, [dataConfigForm]);

  const imagePosition = useMemo(() => {
    return appearanceConfig?.imagePosition;
  }, [appearanceConfig]);

  const targeting = useMemo(() => {
    return dataConfigForm?.setting?.targeting;
  }, [dataConfigForm]);

  const afterSubmission = useMemo(() => dataConfigForm?.setting?.afterSubmission, [dataConfigForm?.setting?.afterSubmission]);

  const formBlockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeaderImageHeight(formBlockRef?.current?.offsetHeight || 0);
  }, [formBlockRef, imagePosition]);

  const isTopOrBottom = useMemo(() => {
    return imagePosition === 'top' || imagePosition === 'bottom' || previewMode === Device.Mobile;
  }, [imagePosition, previewMode]);

  const isMobileView = useMemo(() => {
    return previewMode === Device.Mobile || isMobileTiny;
  }, [previewMode, isMobileTiny]);

  const isShowForm = useMemo(() => {
    if (isMobileView && !targeting?.settingDevice?.includes(FormSettingDevice.Mobile)) return false;
    if (!isMobileView && !targeting?.settingDevice?.includes(FormSettingDevice.Desktop)) return false;
    return true;
  }, [isMobileView, targeting?.settingDevice]);

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    setSubmittedTimes((prev) => prev + 1);
    setLatestSubmittedTime(Date.now());
    setCountDownValue(afterSubmission?.timeDelayAnotherResponse || 20);
    if (afterSubmission?.isShowThankYouMessage) {
      setIsShowThankyouPage(true);
    }
  };

  useEffect(() => {
    if (isSubmitted && countDownValue > 0) {
      const timeout = setTimeout(() => {
        setCountDownValue((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [afterSubmission?.timeDelayAnotherResponse, countDownValue, isSubmitted, latestSubmittedTime, submittedTimes]);

  useEffect(() => {
    if (countDownValue === 0) {
      setIsSubmitted(false);
      setSubmittedTimes(0);
      setLatestSubmittedTime(0);
    }
  }, [countDownValue]);

  const isWaitingCountDown = useMemo(() => {
    return !isShowThankyouPage && countDownValue > 0;
  }, [countDownValue, isShowThankyouPage]);

  return isShowForm ? (
    <>
      <div className="wrap-preview-content">
        <div className="device-container flex-center justify-center" style={{ width: isMobileView ? '375px' : '100%' }}>
          <div
            className={`form-container ${isMobileView ? 'top' : imagePosition}`}
            style={{ width: appearanceConfig?.formWidth, height: appearanceConfig?.formHeight }}
          >
            {appearanceConfig?.isAddImage && appearanceConfig?.imageUrl ? (
              <div
                style={{
                  backgroundImage: `url(${appearanceConfig?.imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: isTopOrBottom ? `${appearanceConfig?.imageHeight}px` : `${headerImageHeight}px`,
                  width: isTopOrBottom ? '100%' : `${appearanceConfig?.imageWidth}px`,
                  flexShrink: 0,
                }}
              />
            ) : null}
            <div
              className="form-block"
              ref={formBlockRef}
              style={{
                padding: isMobileView ? '16px' : '20px 40px 20px',
                minHeight: `calc(100% - ${
                  ((appearanceConfig?.isAddImage && appearanceConfig?.imageUrl && appearanceConfig?.imageHeight) || 0) + 100
                }px)`,
              }}
            >
              {isShowThankyouPage ? (
                <ThankyouPage countDownValue={countDownValue} />
              ) : (
                <FormContent
                  isSubmitted={isSubmitted}
                  isMobileView={isMobileView}
                  handleSubmitForm={handleSubmitForm}
                  countDownValue={countDownValue}
                />
              )}

              <div className="form-footer mt-16">
                {generalConfig?.isFooterAddText && (
                  <p
                    className="mb-8"
                    style={{
                      color: appearanceConfig?.fieldTextColor || '#e4e4e4',
                      fontSize: appearanceConfig?.fieldFontSize || '0.875rem',
                      fontWeight: appearanceConfig?.fieldFontWeight || 'normal',
                      margin: '0 0 8px 0',
                    }}
                  >
                    {generalConfig?.footerContent}
                  </p>
                )}
                <button
                  className={`submit-button ${isWaitingCountDown ? 'disabled' : ''}`}
                  style={{
                    fontSize: appearanceConfig?.buttonFontSize ? appearanceConfig?.buttonFontSize + 'px' : '1rem',
                    fontWeight: appearanceConfig?.buttonFontWeight,
                  }}
                  type={isShowThankyouPage ? 'button' : 'submit'}
                  form="ot-chat-form"
                  onClick={isShowThankyouPage ? () => setIsShowThankyouPage(false) : undefined}
                  disabled={isWaitingCountDown}
                >
                  {isShowThankyouPage
                    ? `Continue`
                    : isWaitingCountDown
                    ? `Continue after ${countDownValue} seconds`
                    : generalConfig?.footerButtonText}
                </button>
                <p className="mt-16 text-end" style={{ fontSize: '0.875rem' }}>
                  Powered by{' '}
                  <a href="https://www.omegatheme.com" style={{ color: '#005BD3' }}>
                    Omega
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{ModalPreviewStyled({ appearance: appearanceConfig }) as ReactNode}</style>
    </>
  ) : (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <b>Form is not shown for this device</b>
    </div>
  );
};

export default ModalPreviewForm;
