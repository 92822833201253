import { PricingPlan } from '@/constants/enum';
import { IToast } from '@/types/components/toast';

export const openCrisp = () => {
  try {
    $crisp.push(['do', 'chat:open']);
  } catch (error) {
    console.log(error);
  }
};
export const sendCrispMessage = (message: string) => {
  try {
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['do', 'message:send', ['text', message]]);
  } catch (err) {
    console.log(err);
  }
};
export const checkShowErrorInline = (
  res: any,
): {
  status: boolean;
  msg: string;
} => {
  try {
    if ('data' in res) {
      return {
        status: res.data.statusCode !== 200,
        msg: res.data.message.toString(),
      };
    } else {
      return {
        status: true,
        msg: res.error.data.message.toString() || 'something happened',
      };
    }
  } catch (err) {
    return {
      status: true,
      msg: 'something happened',
    };
  }
};

export const handleToastMutation = (res: any): IToast => {
  try {
    if ('data' in res) {
      if (res.data.statusCode !== 200) {
        return {
          isOpen: true,
          content: res.data.message || 'Failed',
          error: true,
        };
      } else {
        return {
          isOpen: true,
          content: res.data.message || 'Saved',
          error: false,
        };
      }
    } else {
      return {
        isOpen: true,
        content: res.error.data.message.join(', ') || 'something happened',
        error: false,
      };
    }
  } catch (err) {
    return {
      isOpen: true,
      content: 'something happened',
      error: true,
    };
  }
};

export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

export const renderContentByPlan = (value: string | number, plan?: PricingPlan) => {
  if (!plan || plan === PricingPlan.Free || plan === PricingPlan.Basic) {
    return '---';
  } else {
    return value;
  }
};

export const validateEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email.toLowerCase());
};
