import { IParamsApi } from '@/types/apis/params';
import PhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';

interface IProps {
  appearance?: IParamsApi.IFormSettingAppearance;
}

export const ModalPreviewStyled = ({ appearance }: IProps) => css`
  .wrap-preview-content {
    padding: 16px;
    height: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .device-container {
      background-color: #3d3d3d;
      border: 1px solid;
      padding: 16px;
      border: 1px solid rgb(131, 131, 131);
      .form-container {
        background-color: ${appearance?.formBackgroundColor};
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid #e3e3e3;
        display: flex;
        overflow: auto;
        /* flex-wrap: wrap;
        align-items: center;
        justify-content: center; */
        position: relative;
        &.left {
          flex-direction: row;
          flex-wrap: nowrap !important;
        }
        &.right {
          flex-direction: row-reverse;
          flex-wrap: nowrap !important;
        }
        &.top {
          flex-direction: column;
        }
        &.bottom {
          flex-direction: column-reverse;
        }
        .form-block {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          /* justify-content: center; */
          flex-direction: column;
          flex: 1;
          /* &.left,
          &.right {
            height: 100%;
          } */
          .input-label {
            font-size: 16px;
            font-weight: 500;
          }
          .form-header {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            > p {
              text-align: center;
            }
          }

          .form-content {
            width: 100%;
            overflow: auto;
            form {
              display: flex;
              flex-wrap: wrap;
              gap: 16px;
              width: 100%;
              .divider {
                border: 1px solid #e3e3e3;
                height: 0;
              }
              .form-input {
                width: 100%;
                padding: 16px;
                background-color: ${appearance?.fieldBackgroundColor};
                border-radius: 10px;
                border: 1px solid ${appearance?.fieldBorderColor};
                color: ${appearance?.fieldTextColor};
                font-size: ${appearance?.fieldFontSize}px;
                outline: none;

                /* Placeholder styles */
                &::placeholder {
                  color: ${appearance?.fieldTextColor};
                  font-weight: 400;
                  font-size: ${appearance?.fieldFontSize}px;
                  opacity: 0.7;
                }

                /* Placeholder styles for Firefox */
                &::-moz-placeholder {
                  color: #000;
                  font-weight: 400;
                  font-size: 14px;
                  opacity: 0.7;
                }

                /* Placeholder styles for Edge */
                &::-ms-input-placeholder {
                  color: #000;
                  font-weight: 400;
                  font-size: 14px;
                  opacity: 0.7;
                }

                /* Placeholder styles for Internet Explorer */
                &:-ms-input-placeholder {
                  color: #000;
                  font-weight: 400;
                  font-size: 14px;
                  opacity: 0.7;
                }
              }
              .form-select {
                margin-top: 8px;
                width: 100%;
                background-color: #e3e3e3;
                border-radius: 10px;
                border-width: 1px;
                border-style: solid;
                outline: none;
                padding: 14px 40px 14px 16px;
                cursor: pointer;

                appearance: none;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-position: calc(100% - 20px) center;
                background-size: 16px;

                /* Hover state */
                &:hover {
                  background-color: #dadada;
                }

                /* Focus state */
                &:focus {
                  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
                }

                /* Disabled state */
                &:disabled {
                  opacity: 0.6;
                  cursor: not-allowed;
                }
              }
              .form-input-option {
                align-items: flex-end;
                & + label {
                  line-height: 32px;
                  cursor: pointer;
                }
                &.radio-input {
                  appearance: none;
                  cursor: pointer;
                  border-radius: 50%;
                  width: 16px;
                  height: 16px;

                  border: 2px solid #999;
                  transition: 0.2s all linear;
                  margin-right: 5px;

                  position: relative;
                  top: 4px;
                  &:checked {
                    border: 6px solid #006fbe;
                  }
                }
                &.checkbox-input {
                  appearance: none;
                  cursor: pointer;
                  width: 16px;
                  height: 16px;
                  border: 2px solid #999;
                  border-radius: 4px;
                  position: relative;
                  top: 4px;
                  margin-right: 5px;
                  transition: all 0.2s ease-in-out;

                  &:checked {
                    background-color: #006fbe;
                    border-color: #006fbe;

                    /* Custom checkmark using pseudo-element */
                    &::after {
                      content: '';
                      position: absolute;
                      left: 4px;
                      top: 1px;
                      width: 5px;
                      height: 9px;
                      border: solid white;
                      border-width: 0 2px 2px 0;
                      transform: rotate(45deg);
                    }
                  }

                  &:hover {
                    border-color: #006fbe;
                  }

                  &:focus {
                    outline: 2px solid rgba(0, 111, 190, 0.2);
                    outline-offset: 2px;
                  }

                  /* Disabled state */
                  &:disabled {
                    background-color: #e9ecef;
                    border-color: #ced4da;
                    cursor: not-allowed;
                  }
                }

                /* Style for the label next to checkbox */
                & + label {
                  line-height: 32px;
                  cursor: pointer;
                  user-select: none;

                  &:hover {
                    color: #006fbe;
                  }
                }
              }
            }
          }

          .form-footer {
            width: 100%;
            .submit-button {
              &.disabled {
                background-color: #e3e3e3 !important;
                color: #7f7f7f !important;
                pointer-events: none;
              }
              color: ${appearance?.buttonTextColor};
              background-color: ${appearance?.buttonBackgroundColor};
              padding: 16px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              display: block;
              border-radius: 10px;
              font-size: calc(20 / 16 * 1rem);
              font-weight: 700;
              border: none;
              outline: none;
              cursor: pointer;
              &:hover {
                filter: brightness(1.1);
              }
            }
          }
        }
      }
    }
  }
`;

export const PhoneInputStyled = styled(PhoneInput)<IProps>`
  margin-top: 8px;
  input.form-control {
    width: 100%;
    height: 47px;
    border-radius: 8px;
    border-color: ${(props) => props.appearance?.fieldBorderColor || '#3b82f6'};
    background-color: ${(props) => props.appearance?.fieldBackgroundColor || 'transparent'};
    font-weight: ${(props) => props.appearance?.fieldFontWeight} !important;
    font-size: ${(props) => (props.appearance?.fieldFontSize ? props.appearance?.fieldFontSize + 'px' : '16px')} !important;

    &:not(:placeholder-shown),
    &:not(:placeholder-shown) + .flag-dropdown {
      border-width: 2px;
    }
  }
  .flag-dropdown {
    border-radius: 8px 0 0 8px;
    border-color: ${(props) => props.appearance?.fieldBorderColor || '#3b82f6'};
    .selected-flag {
      border-radius: 8px 0 0 8px;
    }
  }
`;
