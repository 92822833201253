import { IAutoComplete } from '@/types';
import { LegacyStack, Tag, Autocomplete } from '@shopify/polaris';
import { useState, useCallback, memo, useEffect } from 'react';
import { AutoCompleteWithTagStyled } from './styled';

function AutoCompleteWithTag({ ...props }: IAutoComplete.IProps) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    setOptions(props.options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.options)]);
  const updateText = (value: string) => {
    setInputValue(value);
    const resultOptions = props.options.filter((option) => option.label.toLocaleLowerCase().includes(value.toLowerCase()));
    setOptions(resultOptions);
  };

  const removeTag = useCallback(
    (tag: string) => () => {
      const options = [...props.selectedOptions];
      options.splice(options.indexOf(tag), 1);
      props.setSelectedOptions(options);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.selectedOptions],
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={props.label}
      labelHidden={props.labelHidden}
      value={inputValue}
      autoComplete="off"
      requiredIndicator={props.requiredIndicator}
      placeholder={props.placeholder}
      disabled={props.disable}
      helpText={props.helpText}
      error={props.error}
    />
  );
  const tagsMarkup =
    props.selectedOptions.length > 0
      ? props.selectedOptions?.map((option) => {
          return (
            <Tag key={`option${option}`} onRemove={!props.disable ? removeTag(option) : undefined}>
              {props.options.find((item) => item.value === option)?.label || ''}
            </Tag>
          );
        })
      : null;
  const selectedTagMarkup =
    props.selectedOptions.length > 0 ? (
      <div style={{ marginTop: '8px' }}>
        <LegacyStack spacing="extraTight">{tagsMarkup}</LegacyStack>
      </div>
    ) : null;
  return (
    <AutoCompleteWithTagStyled>
      <Autocomplete
        loading={props.loading}
        allowMultiple={props.allowMultiple}
        options={options}
        selected={props.selectedOptions}
        textField={textField}
        onSelect={props.setSelectedOptions}
      />
      {selectedTagMarkup}
    </AutoCompleteWithTagStyled>
  );
}

export default memo(AutoCompleteWithTag);
