import { PATH } from '@/constants/path';
import ContactFormPage from '@/pages/ContactForm';
import Dashboard from '@/pages/Dashboard';
import FormAnswer from '@/pages/FormAnswer';
import FormAnswerDetail from '@/pages/FormAnswer/FormAnswerDetail';
import Home from '@/pages/Home';
import NewWidget from '@/pages/NewWidget';
import PricingPlan from '@/pages/PricingPlan';
import Widgets from '@/pages/Widgets';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

interface IRouter {
  path: string;
  element: React.ReactNode;
}

const router: Array<IRouter> = [
  {
    path: PATH.DEFAULT.pathname,
    element: <Home />,
  },
  {
    path: PATH.ANALYTICS.pathname,
    element: <Dashboard />,
  },
  {
    path: PATH.WIDGETS.pathname,
    element: <Widgets />,
  },
  {
    path: PATH.CONTACT_FORM.pathname,
    element: <ContactFormPage />,
  },
  {
    path: PATH.FORM_ANSWER.pathname,
    element: <FormAnswer />,
  },
  {
    path: PATH.FORM_ANSWER_DETAIL.pathname,
    element: <FormAnswerDetail />,
  },
  {
    path: PATH.PRICING_PLAN.pathname,
    element: <PricingPlan />,
  },
  {
    path: PATH.NEW_WIDGET.pathname,
    element: <NewWidget />,
  },
  {
    path: PATH.EDIT_WIDGET.pathname,
    element: <NewWidget />,
  },
];
function RenderRouter(): JSX.Element {
  return (
    <Routes>
      {router.map((item: IRouter) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}
      <Route path="*" element={<Navigate to={PATH.DEFAULT} replace />} />
    </Routes>
  );
}
export default RenderRouter;
