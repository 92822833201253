import RegularText from '@/components/RegularText';
import { ICon } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import widgetSlice, { customizeSelected } from '@/redux/slice/widget.slice';
import { Button, Link, Text } from '@shopify/polaris';
import { MinusIcon } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WhatsappStyled } from './styled';
import BoldText from '@/components/BoldText';
import { IParamsApi } from '@/types/apis/params';
import { listDefaultAvatarAgents } from '@/components/ChannelsButton/Component/ModalAgent';
interface IProps {
  handleCloseWindow: () => void;
  agentSelected?: IParamsApi.IAgent;
}
const WhatsappWindow = ({ handleCloseWindow, agentSelected }: IProps) => {
  const dispatch = useDispatch();
  const whatsapp = useChannelSelected(ICon.Whatsapp).channel;

  const customize = useSelector(customizeSelected);
  const handleClose = () => {
    handleCloseWindow();
    dispatch(widgetSlice.actions.handleWindow(undefined));
  };

  const filterAvatar = () => {
    if (agentSelected) {
      const avatarUrl = agentSelected.customAvatar
        ? agentSelected.customAvatar
        : listDefaultAvatarAgents.find((avatar) => avatar.id === agentSelected.avatarId)?.url;
      return avatarUrl;
    }
  };

  return (
    <WhatsappStyled borderRadius={customize.buttonStyle}>
      <div className="container-1">
        <div className="heading">
          <div style={{ width: '80%' }}>
            <BoldText>{whatsapp?.advance?.whatsappHeading}</BoldText>
            <div className="mt-8">
              <Text as="span" variant="bodyMd" truncate breakWord>
                {whatsapp?.advance?.whatsappDescription}
              </Text>
            </div>
          </div>
          <div onClick={handleClose}>
            <Button variant="plain" icon={MinusIcon} />
          </div>
        </div>
        <div className="body">
          <div className="d-flex">
            {whatsapp?.advance?.whatsappProfileImage?.url || filterAvatar() ? (
              <img
                style={{ borderRadius: '50%' }}
                className="mr-8"
                src={filterAvatar() || whatsapp?.advance?.whatsappProfileImage?.url}
                alt="whatsapp-avatar"
                width={24}
                height={24}
              />
            ) : null}
            <div className="message-contaner">
              <div className="nickname ellipsis">{agentSelected?.name || whatsapp?.advance?.whatsappNickname}</div>
              <div className="message ellipsis">{whatsapp?.advance?.whatsappWelcomeMessage}</div>
              <div className="time ellipsis">{dayjs().format('hh:mm')}</div>
            </div>
          </div>

          <div className="bottom">
            <div className="input-container">
              <input type="text" placeholder={whatsapp?.advance?.whatsappPlaceholder || 'Write your message...'} disabled></input>
              <div className="send-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_9452_6982)">
                    <path
                      d="M18.5703 9.99996L2.66037 17.6603L5.60665 9.99996L2.66037 2.33963L18.5703 9.99996Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="1.6625"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M8.24069 9.99947L3.07723 9.99992"
                      stroke="#C6D7E3"
                      strokeWidth="1.6625"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_9452_6982">
                      <rect width="20" height="20" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="power-by">
              <RegularText>
                Powered by{' '}
                <Link url="https://www.omegatheme.com/" target="_blank">
                  Omega
                </Link>
              </RegularText>
            </div>
          </div>
        </div>
      </div>
    </WhatsappStyled>
  );
};

export default memo(WhatsappWindow);
