import CriticalBanner from '@/components/CriticalBanner';
import { FormType } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { defaultContactFormData } from '@/redux/slice/editContactForm.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IResponseApi } from '@/types/apis/response';
import { Modal, Select, SelectGroup, SelectOption, TextField } from '@shopify/polaris';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: (form: IResponseApi.IFormData) => void;
}

interface IState {
  name: string;
  type: FormType;
}

interface IError {
  name: string;
  server: string;
}

const initState: IState = {
  name: '',
  type: FormType.Button,
};
const initError = { name: '', server: '' };

const ModalCreateForm = ({ open, onSuccess, onClose }: IProps) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<IState>(initState);
  const [error, setError] = useState<IError>(initError);
  const handleChangeState = (key: keyof IState) => (value: string) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const [createForm, { isLoading }] = apiCaller.useCreateFormMutation();

  const typeOptions = useMemo<(SelectOption | SelectGroup)[]>(() => [{ label: 'Button', value: FormType.Button }], []);

  const handleClose = () => {
    setState(initState);
    setError(initError);
    onClose();
  };

  const handleCreate = () => {
    createForm({ name: state.name, type: state.type, setting: defaultContactFormData.setting }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        handleClose();
        if ('data' in res && onSuccess) {
          onSuccess(res.data.data);
        }
      } else {
        setError((prev) => ({ ...prev, server: condition.msg }));
      }
    });
  };

  return (
    <Modal
      title="Create New Form"
      open={open}
      onClose={handleClose}
      primaryAction={{
        content: 'Create',
        onAction: handleCreate,
        loading: isLoading,
        disabled: !!error.name || !!error.server || !state.name,
      }}
      secondaryActions={[{ content: 'Cancel', onAction: handleClose }]}
    >
      <Modal.Section>
        <CriticalBanner isVisible={!!error.server}>{error.server}</CriticalBanner>
        <div className="d-flex flex-column gap-4">
          <TextField
            label="Form Name"
            value={state.name}
            onChange={(value) => {
              setState((prev) => ({ ...prev, name: value, error: '' }));
            }}
            error={error.name}
            onBlur={(e) => {
              // @ts-ignore
              if (!e?.target?.value) {
                setError((prev) => ({ ...prev, name: 'This field is required' }));
              }
            }}
            autoComplete="off"
            requiredIndicator
          />
          <Select label="Form Type" options={typeOptions} onChange={handleChangeState('type')} value={state.type} />
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default ModalCreateForm;
