import { memo } from 'react';

export interface Props {
  isRequired?: boolean;
  style?: React.CSSProperties;
}

const Required = ({ isRequired = true, style }: Props) => {
  return <span style={{ color: '#af1c02', display: isRequired ? 'inline' : 'none', ...style }}>*</span>;
};

export default memo(Required);
