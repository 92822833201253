import BoldText from '@/components/BoldText';
import editContactFormSlice, { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { Checkbox, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const ContentHeader = () => {
  const dispatch = useDispatch();
  const selectedContactForm = useSelector(selectedContactFormSelector);

  const { isAllowHeader, headerTitle, headerContent } = selectedContactForm?.setting?.general || {};

  const handleChange = (key: keyof IParamsApi.IFormSettingGeneral) => (value: any) => {
    dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'general', key, value }));
  };

  return (
    <>
      <Checkbox label="Allow Header" checked={isAllowHeader} onChange={handleChange('isAllowHeader')} />
      <TextField
        autoComplete="off"
        label={<BoldText>Title</BoldText>}
        value={headerTitle}
        onChange={handleChange('headerTitle')}
      />
      <TextField
        autoComplete="off"
        label={<BoldText>Content</BoldText>}
        value={headerContent}
        onChange={handleChange('headerContent')}
      />
    </>
  );
};

export default ContentHeader;
