import { PricingPlan } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { useSelector } from 'react-redux';

export const useUserPlan = () => {
  const isSkip = useSelector(isSkipApiSelector);
  const { data } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const plan = data?.data?.plan;
  const isLimitedFormAnswer = plan === PricingPlan.Free || plan === PricingPlan.Basic;
  return { plan, isLimitedFormAnswer };
};
