import { SortDirection } from '@/constants/enum';
import { IResponseApi } from '@/types/apis/response';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IParamsApi } from '@/types/apis/params';

const initialState: {
  contactFormParams: IParamsApi.IGetForm;
  contactFormData?: IResponseApi.IFormData[];
} = {
  contactFormParams: {
    page: 1,
    perPage: 10,
    sortDirection: SortDirection.Desc,
  },
  contactFormData: [],
};

export const contactFormSlice = createSlice({
  name: 'contactForm',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setContactFormParams: (state, action: PayloadAction<IParamsApi.IGetForm>) => {
      state.contactFormParams = action.payload;
    },
    setContactFormData: (state, action: PayloadAction<IResponseApi.IFormData[]>) => {
      state.contactFormData = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const contactFormParamsSelector = createSelector(
  (state: RootState) => state.contactForm,
  (state) => state.contactFormParams,
);

export const contactFormDataSelector = createSelector(
  (state: RootState) => state.contactForm,
  (state) => state.contactFormData,
);

export default contactFormSlice;
