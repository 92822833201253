import BoldText from '@/components/BoldText';
import CreateWidgetBtn from '@/components/CreateWidgetBtn';
import { CustomLinkIcon } from '@/components/Icons/CustomLink';
import useVariant from '@/components/Icons/Variant';
import RegularText from '@/components/RegularText';
import SkeletonPage from '@/components/SkeletonPage';
import Switch from '@/components/Switch';
import { BREAKPOINT, Device, ICon, IconIds, PricingPlan } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation, renderContentByPlan } from '@/helpers';
import { listIconPreview } from '@/pages/NewWidget/listIconDefault';

import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import widgetsSlice, { widgetsDataSelector } from '@/redux/slice/widgets.slice';
import { IResponseApi } from '@/types/apis/response';
import { Button, EmptyState, Icon, IndexTable, Modal, Pagination, SkeletonBodyText, Tooltip } from '@shopify/polaris';
import { DeleteIcon, DesktopIcon, EditIcon, MobileIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableWidgetsStyled } from './styled';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
interface IProps {
  endTime: number;
  startTime: number;
  hasAction?: boolean;
}

const Table = ({ endTime, startTime, hasAction }: IProps): JSX.Element => {
  const isSkip = useSelector(isSkipApiSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const location = useLocation();
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });
  const isLimited = useMemo(() => {
    return [PricingPlan.Free, PricingPlan.Basic].includes(dataSetting?.data?.plan || PricingPlan.Free);
  }, [dataSetting?.data?.plan]);
  const variant = useVariant();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const widgetsData = useSelector(widgetsDataSelector);
  const [updateGroupButton, updateGroupButtonStatus] = apiCaller.useUpdateGroupButtonMutation();
  const [removeGroupButton, removeGroupButtonStatus] = apiCaller.useRemoveGroupButtonMutation();
  const { data, isLoading } = apiCaller.useListGroupButtonQuery(
    {
      endTime,
      startTime,
      page: widgetsData.page,
      perPage: widgetsData.perPage,
      sortDirection: widgetsData.sortDirection,
    },
    { skip: isSkip },
  );

  const [modalDelete, setModalDelete] = useState({
    isOpen: false,
    id: 0,
  });

  const toggleRule = useCallback(
    (id: number) => (value: boolean) => {
      updateGroupButton({
        id,
        isActive: value,
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    },
    [dispatch, updateGroupButton],
  );

  const handleDelete = () => {
    removeGroupButton({ id: modalDelete.id }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
      setModalDelete({
        id: 0,
        isOpen: false,
      });
    });
  };

  const calcRate = useCallback((item: IResponseApi.IButtonGroup) => {
    const totalVisitor = item.analytic.desktop.views + item.analytic.mobile.views;
    const totalClick = item.analytic.desktop.clicks + item.analytic.mobile.clicks;
    if (totalVisitor === 0) return 0;
    return ((totalClick * 100) / totalVisitor).toFixed(1);
  }, []);

  const filterIcon = useCallback(
    (channel: IResponseApi.IButton) => {
      const showOn = [];
      if (channel.isDesktop) showOn.push(Device.Pc);
      if (channel.isMobile) showOn.push(Device.Mobile);
      const image = channel.customImage;
      if (channel.iconId === IconIds.Custom) {
        return <img className="preview-icon-item" src={image} width={30} height={30} alt={channel.hoverText} key={channel.id} />;
      }
      if (channel.channel === ICon.CustomLink)
        return (
          <CustomLinkIcon
            channel={{
              ...channel,
              showOn,
              customImage: {
                url: channel.customImage,
              },
            }}
          />
        );
      const result = listIconPreview.find((item) => item.keyValue === channel.channel);
      if (result) {
        if (result.variant) {
          return variant
            .find((item) => item.id === channel.channel)
            ?.variant(40, channel.backgroundColor, channel.iconColor)
            .find((item) => item.id === channel.iconId)?.element;
        } else {
          return result.icon(channel.backgroundColor, channel.iconColor);
        }
      }
    },
    [variant],
  );

  const items = useMemo(() => {
    return data?.data.map((group) => ({
      id: group.id,
      name: (
        <div>
          <BoldText>{group.name}</BoldText>
        </div>
      ),
      listButton: (
        <div className="d-flex preview-icon-container">
          {group.buttons.map((item, index) => {
            if (index < 5) {
              return <div key={item.id}>{filterIcon(item)}</div>;
            }
            return null;
          })}
          {group.buttons.length > 5 ? (
            <div className="preview-icon-item preview-icon-plus">
              <RegularText>+{group.buttons.length - 5}</RegularText>
            </div>
          ) : null}
        </div>
      ),
      device: (
        <div className="table-analytics">
          <Icon source={DesktopIcon} tone="base" />
          <Icon source={MobileIcon} tone="base" />
        </div>
      ),
      visitors: (
        <div className="table-analytics">
          <RegularText>{renderContentByPlan(group.analytic.desktop.views, dataSetting?.data?.plan)}</RegularText>
          <RegularText>{renderContentByPlan(group.analytic.mobile.views, dataSetting?.data?.plan)}</RegularText>
        </div>
      ),
      click: (
        <div className="table-analytics">
          <RegularText>{renderContentByPlan(group.analytic.desktop.clicks, dataSetting?.data?.plan)}</RegularText>
          <RegularText>{renderContentByPlan(group.analytic.mobile.clicks, dataSetting?.data?.plan)}</RegularText>
        </div>
      ),
      clickRate: (
        <div className="table-analytics">
          <RegularText>
            {renderContentByPlan(calcRate(group), dataSetting?.data?.plan)} {isLimited ? '' : '%'}
          </RegularText>
        </div>
      ),
      action: hasAction ? (
        <div className={'btn-container d-flex'}>
          <div className="control-btn control-btn-toggle">
            <Switch
              onSwitch={(checked) => toggleRule(group.id)(checked)}
              isActive={group.isActive}
              isLoading={updateGroupButtonStatus.isLoading}
            />
          </div>
          <div className="control-btn edit-btn">
            <Button
              icon={
                <Tooltip content="Edit">
                  <Icon source={EditIcon} />
                </Tooltip>
              }
              onClick={() => {
                navigate({
                  pathname: `/widgets/${group.id}`,
                  search: PATH.EDIT_WIDGET.search,
                });
              }}
              variant="plain"
            />
          </div>
          <div className="control-btn remove-btn">
            <Button
              icon={
                <Tooltip content="Delete">
                  <Icon source={DeleteIcon} />
                </Tooltip>
              }
              onClick={() =>
                setModalDelete({
                  isOpen: true,
                  id: group.id,
                })
              }
              variant="plain"
            />
          </div>
        </div>
      ) : undefined,
    }));
  }, [
    data?.data,
    dataSetting?.data?.plan,
    calcRate,
    isLimited,
    hasAction,
    updateGroupButtonStatus.isLoading,
    filterIcon,
    toggleRule,
    navigate,
  ]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ action, click, clickRate, name, device, visitors, id, listButton }) => (
      <IndexTable.Row id={id.toString()} key={id} position={id}>
        <IndexTable.Cell className="name-cell">{name}</IndexTable.Cell>
        <IndexTable.Cell>{listButton}</IndexTable.Cell>
        <IndexTable.Cell>{device}</IndexTable.Cell>
        <IndexTable.Cell>{visitors}</IndexTable.Cell>
        <IndexTable.Cell>{click}</IndexTable.Cell>
        <IndexTable.Cell>{clickRate}</IndexTable.Cell>
        {hasAction ? <IndexTable.Cell>{action}</IndexTable.Cell> : null}
      </IndexTable.Row>
    ));
  }, [hasAction, items]);

  const resourceName = {
    singular: 'rule',
    plural: 'rules',
  };

  const checkIfHasFormAnswer = (id: number) => {
    return data?.data?.find((item) => item.id === id)?.buttons?.some((button) => button.channel === ICon.ContactForm);
  };

  return (
    <TableWidgetsStyled>
      {isLoading ? (
        <SkeletonPage />
      ) : (
        <div className="pb-8">
          <IndexTable
            emptyState={
              <EmptyState
                heading={data?.data?.length ? 'No data to display' : 'No widgets created'}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              >
                {
                  <>
                    <RegularText>
                      Add <b>Facebook Messenger, WhatsApp</b>, or <b>20+ other channels</b> to your store to increase sales
                      conversion rates
                    </RegularText>
                    <div className="mt-16">
                      <CreateWidgetBtn
                        buttonTitle="Create a widget"
                        disableCreate={isLimited && ((data?.data?.length || 0) > 1 || location.pathname === '/analytics')}
                      />
                    </div>
                  </>
                }
              </EmptyState>
            }
            resourceName={resourceName}
            itemCount={items?.length || 0}
            headings={
              hasAction
                ? [
                    { title: 'Widget name' },
                    { title: 'Button list' },
                    { title: 'Device' },
                    { title: 'Visitors' },
                    { title: 'Unique clicks' },
                    { title: 'Click rate' },
                    { title: 'Actions' },
                  ]
                : [
                    { title: 'Widget name' },
                    { title: 'Button list' },
                    { title: 'Device' },
                    { title: 'Visitors' },
                    { title: 'Unique clicks' },
                    { title: 'Click rate' },
                  ]
            }
            selectable={false}
          >
            {false ? <SkeletonBodyText lines={16} /> : rowMarkup}
          </IndexTable>
          {data && data?.meta.totalResult > 5 ? (
            <div className="mt-16 paginate">
              <Pagination
                label={
                  data?.meta.totalResult
                    ? `Showing ${(data.meta.currentPage - 1) * Number(data.meta.perPage) + 1} to ${Math.min(
                        data.meta.currentPage * Number(data.meta.perPage),
                        data?.meta.totalResult,
                      )} of ${data?.meta.totalResult} items`
                    : null
                }
                hasPrevious={!isLoading && data && data.meta.currentPage > 1}
                onPrevious={() => {
                  if (data) {
                    dispatch(
                      widgetsSlice.actions.handleData({
                        ...widgetsData,
                        page: data && data.meta.currentPage - 1,
                      }),
                    );
                  }
                }}
                hasNext={
                  !isLoading && data && data.meta.currentPage < Math.ceil(data.meta.totalResult / Number(data.meta.perPage))
                }
                onNext={() => {
                  if (data) {
                    dispatch(
                      widgetsSlice.actions.handleData({
                        ...widgetsData,
                        page: data.meta.currentPage + 1,
                      }),
                    );
                  }
                }}
              />
            </div>
          ) : null}
        </div>
      )}

      <Modal
        open={modalDelete.isOpen}
        onClose={() =>
          setModalDelete({
            id: 0,
            isOpen: false,
          })
        }
        title={'Delete chat widget?'}
        footer={
          <div className="content-right" style={{ width: isMobile ? 'calc(100vw - 3rem)' : '588px' }}>
            <Button
              onClick={() =>
                setModalDelete({
                  id: 0,
                  isOpen: false,
                })
              }
            >
              Cancel
            </Button>
            <div className="ml-8">
              <Button tone="critical" variant="primary" onClick={handleDelete} loading={removeGroupButtonStatus.isLoading}>
                Delete
              </Button>
            </div>
          </div>
        }
      >
        <Modal.Section>
          <RegularText>
            Visitor Analytics {checkIfHasFormAnswer(modalDelete.id) ? 'and Form Answer' : ''} related to this chat widget will be
            also permanently deleted.
          </RegularText>
        </Modal.Section>
      </Modal>
    </TableWidgetsStyled>
  );
};

export default memo(Table);
