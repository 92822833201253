import { CartIcon } from '@/components/Icons/Cart';
import { ContactFormIcon } from '@/components/Icons/ContactForm';
import { CustomLinkIcon } from '@/components/Icons/CustomLink';
import { DiscordIcon } from '@/components/Icons/Discord';
import { EmailIcon } from '@/components/Icons/Email';
import { GoogleMapIcon } from '@/components/Icons/GoogleMap';
import { HomeIcon } from '@/components/Icons/Home';
import { InstagramIcon } from '@/components/Icons/Instagram';
import { LineIcon } from '@/components/Icons/Line';
import { LinkedinIcon } from '@/components/Icons/Linkedin';
import { MessengerIcon } from '@/components/Icons/Messenger';
import { PhoneIcon } from '@/components/Icons/Phone';
import { SMSIcon } from '@/components/Icons/SMS';
import { ScrollIcon } from '@/components/Icons/ScrollTop';
import { SkypeIcon } from '@/components/Icons/Skype';
import { SlackIcon } from '@/components/Icons/Slack';
import { SnapchatIcon } from '@/components/Icons/Snapchat';
import { TeamsIcon } from '@/components/Icons/Teams';
import { TelegramIcon } from '@/components/Icons/Telegram';
import { TiktokIcon } from '@/components/Icons/Tiktok';
import { TwitterIcon } from '@/components/Icons/Twitter';
import { ViberIcon } from '@/components/Icons/Viber';
import { VkontakteIcon } from '@/components/Icons/Vkontakte';
import { WazeIcon } from '@/components/Icons/Waze';
import { WeChatIcon } from '@/components/Icons/WeChat';
import { WhatsappIcon } from '@/components/Icons/Whatsapp';
import { ZaloIcon } from '@/components/Icons/Zalo';
import { ContactFormInputType, Device, ICon, LinkedinType } from '@/constants/enum';
import { IParamsApi } from '@/types/apis/params';
import { WidgetPlatform } from '@/constants/enum';
export interface IIconDefault {
  platform: WidgetPlatform;
  label: string;
  keyValue: ICon;
  icon: React.ReactNode;
  value: IParamsApi.IChannelButton;
}

interface IIconPreview {
  label: string;
  keyValue: ICon;
  icon: (fill?: string, iconColor?: string) => React.ReactNode;
  variant?: boolean;
}

export const defaultSelected: IParamsApi.IChannelButton[] = [
  {
    channel: ICon.Whatsapp,
    value: '+84',
    iconId: 1,
    advance: {
      whatsappWebDirection: true,
      whatsappHeading: 'Chat With Us',
      whatsappDescription: 'Typically replies within a few hours',
      whatsappPlaceholder: 'Write your message...',
      whatsappNickname: 'Zoe Doe',
      whatsappWelcomeMessage: 'Are you in need of support?',
      whatsappChatWidget: true,
    },
    showOn: [Device.Mobile, Device.Pc],
  },
  {
    channel: ICon.Messenger,
    value: '',
    iconId: 1,
    showOn: [Device.Pc, Device.Mobile],
    advance: {
      messengerTitle: 'Chat with us',
      messengerWelcomeMessage: 'Hi! How can help you?',
      messengerPlaceholderText: 'Write your message...',
      messengerChatWidget: true,
    },
  },
];

export const CustomChannel = {
  label: 'Custom',
  keyValue: ICon.CustomLink,
  icon: (backgroundColor: string) => (
    <CustomLinkIcon
      channel={{
        iconId: 1,
        channel: ICon.CustomLink,
        value: '',
        showOn: [Device.Pc, Device.Mobile],
        backgroundColor: backgroundColor,
      }}
    />
  ),
  value: {
    iconId: 1,
    channel: ICon.CustomLink,
    value: '',
    showOn: [Device.Pc, Device.Mobile],
    backgroundColor: '#1E88E5',
  },
};

export const listIconDefault: Array<IIconDefault> = [
  {
    platform: WidgetPlatform.Messaging,
    label: 'Messenger',
    keyValue: ICon.Messenger,
    icon: <MessengerIcon />,
    value: {
      iconId: 1,
      channel: ICon.Messenger,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
      advance: {
        messengerTitle: 'Chat with us',
        messengerWelcomeMessage: 'Hi! How can help you?',
        messengerPlaceholderText: 'Write your message...',
        messengerChatWidget: true,
      },
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'WhatsApp',
    keyValue: ICon.Whatsapp,
    icon: <WhatsappIcon />,
    value: {
      iconId: 1,

      channel: ICon.Whatsapp,
      value: '+84',
      advance: {
        whatsappWebDirection: true,
        whatsappHeading: 'Chat With Us',
        whatsappDescription: 'Typically replies within a few hours',
        whatsappPlaceholder: 'Write your message...',
        whatsappNickname: 'Zoe Doe',
        whatsappWelcomeMessage: 'Are you in need of support?',
        whatsappChatWidget: true,
      },
      showOn: [Device.Mobile, Device.Pc],
    },
  },

  {
    platform: WidgetPlatform.Messaging,
    label: 'Phone',
    keyValue: ICon.Phone,
    icon: <PhoneIcon />,
    value: {
      iconId: 1,
      channel: ICon.Phone,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'SMS',
    keyValue: ICon.Sms,
    icon: <SMSIcon />,
    value: {
      iconId: 1,
      channel: ICon.Sms,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'Line',
    keyValue: ICon.Line,
    icon: <LineIcon />,
    value: {
      iconId: 1,
      channel: ICon.Line,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'Viber',
    keyValue: ICon.Viber,
    icon: <ViberIcon />,
    value: {
      iconId: 1,
      channel: ICon.Viber,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.SocialMedia,
    label: 'X',
    keyValue: ICon.Twitter,
    icon: <TwitterIcon />,
    value: {
      iconId: 1,
      channel: ICon.Twitter,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.SocialMedia,
    label: 'WeChat',
    keyValue: ICon.WeChat,
    icon: <WeChatIcon />,
    value: {
      iconId: 1,
      channel: ICon.WeChat,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.SocialMedia,
    label: 'Snapchat',
    keyValue: ICon.SnapChat,
    icon: <SnapchatIcon />,
    value: {
      iconId: 1,
      channel: ICon.SnapChat,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'Skype',
    keyValue: ICon.Skype,
    icon: <SkypeIcon />,
    value: {
      iconId: 1,
      channel: ICon.Skype,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'Email',
    keyValue: ICon.Email,
    icon: <EmailIcon />,
    value: {
      iconId: 1,
      channel: ICon.Email,
      value: '',
      advance: {
        emailSubject: '',
      },
      showOn: [Device.Mobile, Device.Pc],
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'Instagram',
    keyValue: ICon.Instagram,
    icon: <InstagramIcon />,
    value: {
      iconId: 1,
      channel: ICon.Instagram,
      value: '',
      showOn: [Device.Mobile, Device.Pc],
    },
  },

  {
    platform: WidgetPlatform.ContactForm,
    label: 'Contact Form',
    keyValue: ICon.ContactForm,
    icon: <ContactFormIcon />,
    value: {
      iconId: 1,
      channel: ICon.ContactForm,
      formFields: [
        {
          customId: 0,
          isActive: true,
          isRequired: true,
          title: 'Name',
          placeholder: 'Enter your name',
          typeInput: ContactFormInputType.Text,
        },
        {
          customId: 1,
          isActive: true,
          isRequired: true,
          title: 'Email',
          placeholder: 'Enter your email',
          typeInput: ContactFormInputType.Email,
        },
        {
          customId: 2,
          isActive: true,
          isRequired: true,
          title: 'Phone',
          placeholder: 'Enter your phone number',
          typeInput: ContactFormInputType.Phone,
        },
        {
          customId: 3,
          isActive: true,
          isRequired: true,
          title: 'Message',
          placeholder: 'Enter your message',
          typeInput: ContactFormInputType.Text,
        },
      ],
      value: '',
      showOn: [Device.Pc, Device.Mobile],
      hoverText: 'Contact Us!',
      advance: {
        formTitle: 'Get in touch with us!',
        formSubmitButtonText: 'Chat now',
        formSubmitButtonTextColor: '#ffffff',
        formSubmitedMessage: 'Your message has been sent! We’ll be in touch shortly. Thanks for contacting us!',
        isFormSubmitedRedirect: false,
        formSubmitedRedirectUrl: '',
        formSubmitedRedirectUrlNewtab: false,
        isFormSubmitedAutoClose: false,
        formSubmitedCloseAfter: 5,
        formRequiredSubmitBeforeChat: false,
        formDescription: 'Please provide a few details so we can assist you better',
      },
    },
  },
  // {
  //   label: 'Poptin Popups',
  //   keyValue: ICon.POPTINPOPUPS,
  //   icon: <PoptinPopupsIcon />,
  //   value: {
  //     iconId: 1,
  //     channel: ICon.POPTINPOPUPS,
  //     value: '',
  //     showOn: [Device.Pc, Device.Mobile],
  //   },
  // },

  {
    platform: WidgetPlatform.Messaging,
    label: 'Telegram',
    keyValue: ICon.Telegram,
    icon: <TelegramIcon />,
    value: {
      iconId: 1,
      channel: ICon.Telegram,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Location,
    label: 'Google Maps',
    keyValue: ICon.GoogleMap,
    icon: <GoogleMapIcon />,
    value: {
      iconId: 1,
      channel: ICon.GoogleMap,
      value: '',
      showOn: [Device.Mobile, Device.Pc],
    },
  },

  {
    platform: WidgetPlatform.SocialMedia,
    label: 'TikTok',
    keyValue: ICon.TikTok,
    icon: <TiktokIcon />,
    value: {
      iconId: 1,
      channel: ICon.TikTok,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },

  {
    platform: WidgetPlatform.Location,
    label: 'Waze',
    keyValue: ICon.Waze,
    icon: <WazeIcon />,
    value: {
      iconId: 1,
      channel: ICon.Waze,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.SocialMedia,
    label: 'LinkedIn',
    keyValue: ICon.LinkedIn,
    icon: <LinkedinIcon />,
    value: {
      iconId: 1,
      channel: ICon.LinkedIn,
      value: '',
      advance: {
        linkedinType: LinkedinType.Personal,
      },
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.SocialMedia,
    label: 'VKontakte',
    keyValue: ICon.Vkontakte,
    icon: <VkontakteIcon />,
    value: {
      iconId: 1,
      channel: ICon.Vkontakte,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Collaboration,
    label: 'Slack',
    keyValue: ICon.Slack,
    icon: <SlackIcon />,
    value: {
      iconId: 1,
      channel: ICon.Slack,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Collaboration,
    label: 'Discord',
    keyValue: ICon.Discrod,
    icon: <DiscordIcon />,
    value: {
      iconId: 1,
      channel: ICon.Discrod,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Collaboration,
    label: 'Teams',
    keyValue: ICon.Teams,
    icon: <TeamsIcon />,
    value: {
      iconId: 1,
      channel: ICon.Teams,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Messaging,
    label: 'Zalo',
    keyValue: ICon.Zalo,
    icon: <ZaloIcon />,
    value: {
      iconId: 1,
      channel: ICon.Zalo,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Navigation,
    label: 'Scroll to top',
    keyValue: ICon.ScrollToTop,
    icon: <ScrollIcon />,
    value: {
      iconId: 1,
      channel: ICon.ScrollToTop,
      value: '',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Navigation,
    label: 'Home page',
    keyValue: ICon.HomePage,
    icon: <HomeIcon />,
    value: {
      iconId: 1,
      channel: ICon.HomePage,
      value: '/',
      showOn: [Device.Pc, Device.Mobile],
    },
  },
  {
    platform: WidgetPlatform.Navigation,
    label: 'Cart page',
    keyValue: ICon.CartPage,
    icon: <CartIcon />,
    value: {
      iconId: 1,
      channel: ICon.CartPage,
      value: `/cart`,
      showOn: [Device.Pc, Device.Mobile],
    },
  },
];
export const listIconPreview: Array<IIconPreview> = [
  {
    label: 'Messenger',
    keyValue: ICon.Messenger,
    icon: (fill?: string, iconColor?: string) => <MessengerIcon fill={fill} iconColor={iconColor} />,
    variant: true,
  },
  {
    label: 'WhatsApp',
    keyValue: ICon.Whatsapp,
    icon: (fill?: string, iconColor?: string) => <WhatsappIcon fill={fill} iconColor={iconColor} />,
  },

  {
    label: 'Phone',
    keyValue: ICon.Phone,
    icon: (fill?: string, iconColor?: string) => <PhoneIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'SMS',
    keyValue: ICon.Sms,
    icon: (fill?: string, iconColor?: string) => <SMSIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Line',
    keyValue: ICon.Line,
    icon: (fill?: string, iconColor?: string) => <LineIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Viber',
    keyValue: ICon.Viber,
    icon: (fill?: string, iconColor?: string) => <ViberIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'X',
    keyValue: ICon.Twitter,
    icon: (fill?: string, iconColor?: string) => <TwitterIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'WeChat',
    keyValue: ICon.WeChat,
    icon: (fill?: string, iconColor?: string) => <WeChatIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Snapchat',
    keyValue: ICon.SnapChat,
    icon: (fill?: string, iconColor?: string) => <SnapchatIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Skype',
    keyValue: ICon.Skype,
    icon: (fill?: string, iconColor?: string) => <SkypeIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Email',
    keyValue: ICon.Email,
    icon: (fill?: string, iconColor?: string) => <EmailIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Instagram',
    keyValue: ICon.Instagram,
    icon: (fill?: string, iconColor?: string) => <InstagramIcon fill={fill} iconColor={iconColor} />,
  },

  {
    label: 'Telegram',
    keyValue: ICon.Telegram,
    icon: (fill?: string, iconColor?: string) => <TelegramIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Google Maps',

    keyValue: ICon.GoogleMap,
    icon: (fill?: string, iconColor?: string) => <GoogleMapIcon fill={fill} iconColor={iconColor} />,
  },

  {
    label: 'TikTok',
    keyValue: ICon.TikTok,
    icon: (fill?: string, iconColor?: string) => <TiktokIcon fill={fill} iconColor={iconColor} />,
  },

  {
    label: 'Waze',
    keyValue: ICon.Waze,
    icon: (fill?: string, iconColor?: string) => <WazeIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'LinkedIn',
    keyValue: ICon.LinkedIn,
    icon: (fill?: string, iconColor?: string) => <LinkedinIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'VKontakte',
    keyValue: ICon.Vkontakte,
    icon: (fill?: string, iconColor?: string) => <VkontakteIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Slack',
    keyValue: ICon.Slack,
    icon: (fill?: string, iconColor?: string) => <SlackIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Discord',
    keyValue: ICon.Discrod,
    icon: (fill?: string, iconColor?: string) => <DiscordIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Contact form',
    keyValue: ICon.ContactForm,
    icon: (fill?: string, iconColor?: string) => <ContactFormIcon fill={fill} iconColor={iconColor} />,
  },
  // {
  //   label: 'Poptin Popups',
  //   keyValue: ICon.POPTINPOPUPS,
  //   icon: (fill?: string, iconColor?: string) => <PoptinPopupsIcon fill={fill} iconColor={iconColor} />,
  // },
  {
    label: 'Teams',
    keyValue: ICon.Teams,
    icon: (fill?: string, iconColor?: string) => <TeamsIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Zalo',
    keyValue: ICon.Zalo,
    icon: (fill?: string, iconColor?: string) => <ZaloIcon fill={fill} iconColor={iconColor} />,
  },
  {
    label: 'Scroll to top',
    keyValue: ICon.ScrollToTop,
    icon: (fill?: string, iconColor?: string) => <ScrollIcon fill={fill} iconColor={iconColor} />,
    variant: true,
  },
  {
    label: 'Home page',
    keyValue: ICon.HomePage,
    icon: (fill?: string, iconColor?: string) => <HomeIcon fill={fill} iconColor={iconColor} />,
    variant: true,
  },
  {
    label: 'Cart page',
    keyValue: ICon.CartPage,
    icon: (fill?: string, iconColor?: string) => <CartIcon fill={fill} iconColor={iconColor} />,
    variant: true,
  },
];
