import styled from 'styled-components';

export const ColorPickerStyled = styled.div<{ backgroundColor?: string; borderRadius?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  .icon-background-color {
    background-color: ${(props) => props.backgroundColor} !important;
    border-radius: ${(props) => props.borderRadius ?? '50%'};
    width: 2rem;
    height: 2rem;
    /* background-color: black; */
    margin-top: 4px;
    cursor: pointer;
    border: 1px solid #a0a0a0a0;
    img {
      width: 2rem;
      height: 2rem;
    }
  }
  .description {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4px;
  }
  label {
    height: 40px;
  }
  input {
    width: 0;
    height: 0;
    padding: 0;
    border: none;
    opacity: 0;
  }
`;
