import { ICon, PricingPlan } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ChannelsButton from '.';

interface IProps {
  keyValue: ICon;
}

const ContactForm = ({ keyValue }: IProps) => {
  const channel = useChannelSelected(keyValue).channel;
  const isSkip = useSelector(isSkipApiSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery(undefined, { skip: isSkip });

  const isLimited = useMemo(() => {
    return dataSetting?.data?.plan === PricingPlan.Free || dataSetting?.data?.plan === PricingPlan.Basic;
  }, [dataSetting?.data?.plan]);

  return channel ? (
    <ChannelsButton
      key={`${keyValue} channels button`}
      keyValue={keyValue}
      placeholder="Contact Form"
      label="Hover Text"
      isReverseContentToBottom
      disabledInput={isLimited}
      isShowLimitedText={isLimited}
      limitedTitle="Upgrade to Pro or Premium plan"
      limitedContent="to receive responses from contact form directly in your email."
      isContactForm={true}
    />
  ) : null;
};
export default memo(ContactForm);
