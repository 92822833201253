import { FormFieldWidth, FormSettingDevice, FormSettingFieldType, FormType, ImagePosition } from '@/constants/enum';
import { contactFormConstants, IContactForm } from '@/pages/ContactForm/components/ModalEditContactForm/config';
import { IParamsApi } from '@/types/apis/params';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { deepObjectEqual } from '@/helpers/object';

export interface IEditContactFormState {
  currentPath: IContactForm.Path;
  selectedContentIndex?: number;
  backupSelectedContactForm: IParamsApi.ICreateForm;
  selectedContactForm: IParamsApi.ICreateForm;
  error: {
    email?: string;
    showSpecificLocations?: string;
    notShowSpecificLocations?: string;
    server?: string;
  };
}

export const defaultContactFormData: IParamsApi.ICreateForm = {
  name: 'string',
  type: FormType.Button,
  isActive: true,
  isTriggerBeforeChatSession: false,
  isDataSync: false,
  setting: {
    general: {
      isAllowHeader: true,
      headerTitle: 'Contact us',
      headerContent: 'Leave your message so we can support you better!',
      isFooterAddText: false,
      footerContent: '',
      footerButtonText: 'Submit',
      fields: [
        {
          type: FormSettingFieldType.Text,
          label: 'First Name',
          width: FormFieldWidth.Half,
          order: 0,
        },
        {
          type: FormSettingFieldType.Text,
          label: 'Last Name',
          width: FormFieldWidth.Half,
          order: 1,
        },
        {
          type: FormSettingFieldType.Email,
          label: 'Email',
          width: FormFieldWidth.Full,
          order: 2,
        },
        {
          type: FormSettingFieldType.Phone,
          label: 'Phone',
          width: FormFieldWidth.Full,
          order: 3,
        },
      ],
      formAgreeEmailSubscription: false,
      formAgreeEmailContent: 'Subscribe for latest news!',
      formAgreeSMSSubscription: false,
      formAgreeSMSContent: 'Subscribe for latest news!',
    },
    appearance: {
      formHeight: 400,
      formWidth: 350,
      isAddImage: true,
      imagePosition: ImagePosition.Top,
      imageHeight: 150,
      imageWidth: 150,
      headerTextColor: '#000000',
      headerFontSize: IContactForm.HeaderFontSize.Medium,
      fieldFontWeight: IContactForm.FontWeight.Regular,
      fieldFontSize: '16',
      fieldTextColor: '#000000',
      fieldBackgroundColor: '#e3e3e3',
      fieldBorderColor: '#e3e3e3',
      buttonFontSize: '16',
      buttonFontWeight: IContactForm.FontWeight.Regular,
      buttonTextColor: '#ffffff',
      buttonBackgroundColor: '#006FBE',
      formBackgroundColor: '#ffffff',
    },
    afterSubmission: {
      timeDelayAnotherResponse: 20,
      isCloseFormAutomatically: true,
      timeAfterCloseFormAutomatically: 5,
      isRedirectVisitor: false,
      isShowThankYouMessage: true,
      submissionTitle: 'Thank you',
      submissionContent: 'Leave your message so we can support you better!',
    },
    targeting: {
      settingDevice: [FormSettingDevice.Desktop, FormSettingDevice.Mobile],
      isShowSpecificLocations: false,
      showSpecificLocations: '',
      isNotShowSpecificLocations: false,
      notShowSpecificLocations: '',
    },
    email: {
      isReceiveEmail: true,
      emails: '',
    },
  },
};

const initialState: IEditContactFormState = {
  currentPath: IContactForm.Path.Content,
  backupSelectedContactForm: defaultContactFormData,
  selectedContactForm: defaultContactFormData,
  error: {},
};

export const editContactFormSlice = createSlice({
  name: 'editContactForm',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleResetState: (state) => {
      state.currentPath = initialState.currentPath;
      state.selectedContentIndex = undefined;
      state.selectedContactForm = initialState.selectedContactForm;
      state.error = { ...state.error, server: '' };
    },
    handleDiscardChanges: (state) => {
      state.currentPath = initialState.currentPath;
      state.selectedContentIndex = undefined;
      state.selectedContactForm = state.backupSelectedContactForm;
      state.error = { ...state.error, server: '' };
    },
    handleSetSelectedContactForm: (state, action: PayloadAction<IParamsApi.ICreateForm>) => {
      state.selectedContactForm = action.payload;
    },
    handleSetBackupSelectedContactForm: (state, action: PayloadAction<IParamsApi.ICreateForm>) => {
      state.backupSelectedContactForm = action.payload;
    },
    handleChangePath: (state, action: PayloadAction<IContactForm.Path>) => {
      state.currentPath = action.payload;
    },
    handleAddContent: (state, action: PayloadAction<FormSettingFieldType>) => {
      if (!state.selectedContactForm.setting?.general?.fields) {
        console.log('Fields not found');
        return;
      }
      state.selectedContactForm.setting.general.fields = [
        ...state.selectedContactForm.setting.general.fields,
        contactFormConstants.defaultInputContentData[action.payload],
      ];
      state.error = { ...state.error, server: '' };
    },
    handleDeleteContent: (state, action: PayloadAction<number>) => {
      if (!state.selectedContactForm.setting?.general?.fields) {
        console.log('Fields not found');
        return;
      }
      const newFields = state.selectedContactForm.setting.general.fields.filter((_, index) => index !== action.payload);
      state.selectedContactForm.setting.general.fields = newFields;
      if (!newFields.some((item) => item.type === FormSettingFieldType.Email)) {
        state.selectedContactForm.setting.general.formAgreeEmailSubscription = false;
      }
      if (!newFields.some((item) => item.type === FormSettingFieldType.Phone)) {
        state.selectedContactForm.setting.general.formAgreeSMSSubscription = false;
      }
      state.error = { ...state.error, server: '' };
    },
    handleDuplicateContent: (state, action: PayloadAction<number>) => {
      if (!state.selectedContactForm.setting?.general?.fields) {
        console.log('Fields not found');
        return;
      }
      const currentContent = state.selectedContactForm.setting.general.fields[action.payload];
      state.selectedContactForm.setting.general.fields = [
        ...state.selectedContactForm.setting.general.fields,
        { ...currentContent },
      ];
      state.error = { ...state.error, server: '' };
    },
    handleSetContent: (state, action: PayloadAction<IParamsApi.IFormSettingField[]>) => {
      if (!state.selectedContactForm.setting?.general) {
        console.log('General not found');
        return;
      }
      state.selectedContactForm.setting.general.fields = action.payload;
    },
    handleSelectContent: (state, action: PayloadAction<number>) => {
      state.selectedContentIndex = action.payload;
    },
    handleChangeSelectedContentData: (
      state,
      action: PayloadAction<{
        key: keyof IParamsApi.IFormSettingField;
        value: IParamsApi.IFormSettingField[keyof IParamsApi.IFormSettingField];
      }>,
    ) => {
      const { key, value } = action.payload;
      if (!state.selectedContactForm.setting?.general?.fields) {
        console.log('Fields not found');
        return;
      }

      state.selectedContactForm.setting.general.fields = state.selectedContactForm.setting.general.fields.map(
        (content, index) => {
          if (index === state.selectedContentIndex) {
            return {
              ...content,
              [key]: value,
            };
          }
          return content;
        },
      );
      state.error = { ...state.error, server: '' };
    },
    handleChangeSetting: (
      state,
      action: PayloadAction<{
        settingType: keyof IParamsApi.IFormSetting;
        key: string;
        value: any;
      }>,
    ) => {
      const { settingType, key, value } = action.payload;
      if (!state.selectedContactForm.setting?.[settingType]) {
        console.log(`${settingType} not found`);
        return;
      }
      state.selectedContactForm.setting[settingType] = {
        ...state.selectedContactForm.setting[settingType],
        [key]: value,
      } as any;
      state.error = { ...state.error, server: '' };
    },
    handleSetError: (state, action: PayloadAction<{ key: keyof IEditContactFormState['error']; value: string }>) => {
      const { key, value } = action.payload;
      state.error = { ...state.error, [key]: value };
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const currentContactFormPathSelector = createSelector(
  (state: RootState) => state.editContactForm,
  (state) => state.currentPath,
);

export const contactFormContentSelector = createSelector(
  (state: RootState) => state.editContactForm,
  (state) => state.selectedContactForm.setting?.general?.fields,
);

export const contactFormSelectedContentSelector = createSelector(
  (state: RootState) => state.editContactForm,
  (state) =>
    state.selectedContentIndex !== undefined
      ? state.selectedContactForm.setting?.general?.fields?.[state.selectedContentIndex]
      : undefined,
);

export const selectedContactFormSelector = createSelector(
  (state: RootState) => state.editContactForm,
  (state) => state.selectedContactForm,
);

export const isSelectedContactFormChangedSelector = createSelector(
  (state: RootState) => state.editContactForm,
  (state) => {
    const isChanged = !deepObjectEqual(state.selectedContactForm, state.backupSelectedContactForm);
    return isChanged;
  },
);

export const contactFormErrorSelector = createSelector(
  (state: RootState) => state.editContactForm,
  (state) => state.error,
);

export default editContactFormSlice;
