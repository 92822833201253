import { Ga4Event } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import {
  isSubmittedEstimatedSelector,
  isSubmittedOderTrackingSelector,
  isSubmittedReturnSelector,
  isSubmittedSyncTrackSelector,
  sessionSlice,
} from '@/redux/slice/session.slice';
import { useDispatch, useSelector } from 'react-redux';

export const useSendGA4Event = () => {
  const dispatch = useDispatch();
  const [sendGA4Event] = apiCaller.useSendGA4EventMutation();
  const isSubmittedEstimated = useSelector(isSubmittedEstimatedSelector);
  const isSubmittedReturn = useSelector(isSubmittedReturnSelector);
  const isSubmittedOrderTracking = useSelector(isSubmittedOderTrackingSelector);
  const isSubmittedSyncTrack = useSelector(isSubmittedSyncTrackSelector);

  return (event: Ga4Event) => {
    const data = {
      eventName: event,
      params: {},
    };
    switch (event) {
      case Ga4Event.EstimatedClick:
        if (!isSubmittedEstimated) {
          sendGA4Event(data);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedEstimated', value: true }));
        }
        break;
      case Ga4Event.ReturnClick:
        if (!isSubmittedReturn) {
          sendGA4Event(data);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedReturn', value: true }));
        }
        break;
      case Ga4Event.OderTrackingClick:
        if (!isSubmittedOrderTracking) {
          sendGA4Event(data);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedOrderTracking', value: true }));
        }
        break;
      case Ga4Event.SynctrackClick:
        if (!isSubmittedSyncTrack) {
          sendGA4Event(data);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedSyncTrack', value: true }));
        }
        break;
      case Ga4Event.SuggestedSyncTrackClick:
        if (!isSubmittedSyncTrack) {
          sendGA4Event(data);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedSyncTrack', value: true }));
        }
        break;
      default:
        break;
    }
  };
};
