import { validateEmail } from '@/helpers';
import editContactFormSlice, { contactFormErrorSelector, selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { Checkbox, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const Email = () => {
  const dispatch = useDispatch();
  const selectedContactForm = useSelector(selectedContactFormSelector);
  const error = useSelector(contactFormErrorSelector);

  const { emails, isReceiveEmail } = selectedContactForm?.setting?.email || {};

  const handleChange = (key: keyof IParamsApi.IFormSettingEmail) => (value: any) => {
    dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'email', key, value }));
  };

  const handleValidateEmails = (input?: string) => {
    const emails = input?.split(',').map((email) => email.trim());
    const isValidEmails = emails && emails.length > 0 && emails.some((email) => !validateEmail(email));
    dispatch(
      editContactFormSlice.actions.handleSetError({
        key: 'email',
        value: isValidEmails ? 'Please enter valid email address' : '',
      }),
    );
  };

  return (
    <>
      <Checkbox
        label="Receive form answers from customers"
        checked={isReceiveEmail}
        onChange={(isChecked) => {
          handleChange('isReceiveEmail')(isChecked);
          if (isChecked) {
            handleValidateEmails(emails);
          } else {
            dispatch(editContactFormSlice.actions.handleSetError({ key: 'email', value: '' }));
          }
        }}
      />
      {isReceiveEmail && (
        <div>
          <TextField
            autoComplete="off"
            placeholder="Example: kevin@a.com, john@b.com"
            label={''}
            value={emails}
            onChange={handleChange('emails')}
            error={error.email}
            onBlur={(e?: React.FocusEvent<HTMLInputElement>) => {
              handleValidateEmails(e?.target.value);
            }}
          />
          <div className="mt-4">You can put multiple email addresses separated with a comma.</div>
        </div>
      )}
    </>
  );
};

export default Email;
