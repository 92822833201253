import { Device, FormSettingFieldType } from '@/constants/enum';
import ModalPreviewForm from '@/pages/ContactForm/components/ModalPreview';
import { Badge, Button, ButtonGroup, Icon, Image, Layout, Modal, TextField } from '@shopify/polaris';
import {
  AlertDiamondIcon,
  CheckIcon,
  ChevronLeftIcon,
  DesktopIcon,
  EditIcon,
  EmailIcon,
  MobileIcon,
  OutgoingIcon,
  SettingsIcon,
  TextBlockIcon,
  WandIcon,
  XIcon,
} from '@shopify/polaris-icons';
import { useMemo, useState } from 'react';

import BoldText from '@/components/BoldText';
import CriticalBanner from '@/components/CriticalBanner';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import editContactFormSlice, {
  contactFormErrorSelector,
  currentContactFormPathSelector,
  isSelectedContactFormChangedSelector,
  selectedContactFormSelector,
} from '@/redux/slice/editContactForm.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { useDispatch, useSelector } from 'react-redux';
import { contactFormConstants, IContactForm, scrollModalEditContactFormToTop } from './config';
import { ModalEditContactFormStyled } from './styled';

interface IProps {
  open: boolean;
  onClose: () => void;
  formSelected?: any;
}

const navList = [
  {
    icon: TextBlockIcon,
    path: IContactForm.Path.Content,
  },
  {
    icon: WandIcon,
    path: IContactForm.Path.Appearance,
  },
  {
    icon: OutgoingIcon,
    path: IContactForm.Path.AfterSubmission,
  },
  {
    icon: SettingsIcon,
    path: IContactForm.Path.Settings,
  },
  {
    icon: EmailIcon,
    path: IContactForm.Path.Email,
  },
];

const ModalEditContactForm = ({ open, onClose }: IProps) => {
  const { routes } = contactFormConstants;
  const dispatch = useDispatch();
  const isShowActions = useSelector(isSelectedContactFormChangedSelector);
  const currentPath = useSelector(currentContactFormPathSelector);
  const dataConfigForm = useSelector(selectedContactFormSelector);
  const error = useSelector(contactFormErrorSelector);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [isShowDiscardModal, setIsShowDiscardModal] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [name, setName] = useState(dataConfigForm?.name);

  const [previewMode, setPreviewMode] = useState<Device>(Device.Pc);

  const [update, { isLoading }] = apiCaller.useUpdateFormMutation();

  const handleUpdateForm = () => {
    const dataUpdate =
      dataConfigForm.setting && dataConfigForm.setting?.general
        ? {
            ...dataConfigForm,
            setting: {
              ...dataConfigForm.setting,
              general: {
                ...dataConfigForm.setting.general,
                fields: dataConfigForm.setting.general.fields?.map((item, index) => {
                  return {
                    ...item,
                    order: index,
                  };
                }),
                formAgreeEmailSubscription: dataConfigForm.setting.general.fields?.some(
                  (item) => item.type === FormSettingFieldType.Email,
                )
                  ? dataConfigForm.setting.general.formAgreeEmailSubscription
                  : false,
                formAgreeSMSSubscription: dataConfigForm.setting.general.fields?.some(
                  (item) => item.type === FormSettingFieldType.Phone,
                )
                  ? dataConfigForm.setting.general.formAgreeSMSSubscription
                  : false,
              },
            },
          }
        : dataConfigForm;
    update(dataUpdate).then((res) => {
      const { status, msg } = checkShowErrorInline(res);
      if (!status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        if ('data' in res) {
          dispatch(editContactFormSlice.actions.handleSetBackupSelectedContactForm(dataConfigForm));
        }
      } else {
        dispatch(editContactFormSlice.actions.handleSetError({ key: 'server', value: msg }));
      }
    });
  };
  const currentRoute = useMemo(() => routes.find((item) => item.path === currentPath), [currentPath, routes]);
  return (
    <>
      <Modal
        title={
          isShowActions ? (
            <div className="d-flex justify-between align-center w-full">
              <div className="d-flex align-center gap-8" style={{ minHeight: '32px' }}>
                <Icon source={AlertDiamondIcon} />
                <BoldText variant="headingLg">Unsaved changes</BoldText>
              </div>
              <div className="d-flex gap-8">
                <Button
                  onClick={() => {
                    setIsShowDiscardModal(true);
                  }}
                >
                  Discard
                </Button>
                <Button
                  variant="primary"
                  loading={isLoading}
                  disabled={Object.values(error).some((item) => !!item)}
                  onClick={handleUpdateForm}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="d-flex align-center gap-8">
              <Image alt="logo" source="/logo.png" width={32} height={32} />
              <BoldText variant="headingLg">Omega Messenger Chat Form</BoldText>
            </div>
          )
        }
        size="large"
        open={open}
        onClose={() => {
          if (isShowActions) {
            setIsShowConfirmModal(true);
            return;
          }
          dispatch(editContactFormSlice.actions.handleResetState());
          onClose();
        }}
      >
        <div id="modal-create-edit-form">
          <CriticalBanner isVisible={!!error.server} marginBottom="0">
            {error.server}
          </CriticalBanner>

          <Layout>
            <Layout.Section variant="oneThird">
              <div className="d-flex h-full">
                <div className="section-left">
                  {navList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`nav-icon ${currentPath.startsWith(item.path) ? 'nav-icon__active' : ''}`}
                        onClick={() => {
                          scrollModalEditContactFormToTop();
                          dispatch(editContactFormSlice.actions.handleChangePath(item.path));
                        }}
                      >
                        <Icon source={item.icon} />
                      </div>
                    );
                  })}
                </div>
                <div className="section-right">
                  <div className="section-header">
                    <div className="d-flex align-center gap-8 remove-icon-margin">
                      {currentRoute?.previousPath ? (
                        <Button
                          variant="tertiary"
                          icon={ChevronLeftIcon}
                          onClick={() => {
                            scrollModalEditContactFormToTop();
                            dispatch(editContactFormSlice.actions.handleChangePath(currentRoute?.previousPath!));
                          }}
                        />
                      ) : null}
                      <div className="section-title section-title__bold">{currentRoute?.title ?? ''}</div>
                    </div>
                  </div>
                  <div className="section-content">
                    {currentRoute?.isRemoveWrapper ? (
                      currentRoute?.element ?? null
                    ) : (
                      <div className="content-wrapper">{currentRoute?.element ?? null}</div>
                    )}
                  </div>
                </div>
              </div>
            </Layout.Section>
            <Layout.Section>
              <div className="section-header">
                <div className="d-flex align-center gap-8">
                  <div className="section-title">
                    {isEditName ? (
                      <TextField
                        label=""
                        labelHidden
                        autoComplete="off"
                        value={name}
                        onChange={(value) => {
                          setName(value);
                        }}
                      />
                    ) : (
                      dataConfigForm?.name
                    )}
                  </div>
                  <Button
                    icon={isEditName ? CheckIcon : EditIcon}
                    variant="plain"
                    onClick={() => {
                      setIsEditName((prev) => !prev);
                      if (isEditName) {
                        dispatch(
                          editContactFormSlice.actions.handleSetSelectedContactForm({
                            ...dataConfigForm,
                            name: name,
                          }),
                        );
                      } else {
                        setName(dataConfigForm?.name);
                      }
                    }}
                  />
                  {isEditName ? (
                    <Button
                      icon={XIcon}
                      variant="plain"
                      onClick={() => {
                        setIsEditName(false);
                        setName(dataConfigForm?.name);
                      }}
                    />
                  ) : null}
                  {dataConfigForm?.isActive ? <Badge tone="success">Active</Badge> : <Badge>Draft</Badge>}
                </div>
                <ButtonGroup variant="segmented">
                  <Button
                    pressed={previewMode === Device.Mobile}
                    onClick={() => setPreviewMode(Device.Mobile)}
                    icon={MobileIcon}
                  />
                  <Button pressed={previewMode === Device.Pc} onClick={() => setPreviewMode(Device.Pc)} icon={DesktopIcon} />
                </ButtonGroup>
              </div>
              <ModalPreviewForm previewMode={previewMode} />
            </Layout.Section>
          </Layout>
          <style>{ModalEditContactFormStyled.toString()}</style>
        </div>
      </Modal>
      <Modal
        title="You have unsaved changes"
        sectioned
        open={isShowConfirmModal}
        onClose={() => setIsShowConfirmModal(false)}
        primaryAction={{
          content: 'Leave',
          onAction: () => {
            dispatch(editContactFormSlice.actions.handleResetState());
            setIsShowConfirmModal(false);
            onClose();
          },
          //@ts-ignore
          tone: 'critical',
        }}
        secondaryActions={[{ content: 'Cancel', onAction: () => setIsShowConfirmModal(false) }]}
      >
        <div id="edit-contact-form-confirm-id">If you leave, all unsaved changes will be lost.</div>
      </Modal>
      <Modal
        title="Discard all unsaved changes"
        sectioned
        open={isShowDiscardModal}
        onClose={() => setIsShowDiscardModal(false)}
        primaryAction={{
          content: 'Discard changes',
          onAction: () => {
            dispatch(editContactFormSlice.actions.handleDiscardChanges());
            setIsShowDiscardModal(false);
          },
          //@ts-ignore
          tone: 'critical',
        }}
        secondaryActions={[{ content: 'Continue editing', onAction: () => setIsShowDiscardModal(false) }]}
      >
        <div id="edit-contact-form-discard-id">
          If you discard changes, you’ll delete any edits you made since you last saved.
        </div>
      </Modal>
    </>
  );
};

export default ModalEditContactForm;
