import { FormSettingFieldType } from '@/constants/enum';
import editContactFormSlice, { selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { Checkbox, TextField } from '@shopify/polaris';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ContentSubscription = () => {
  const dispatch = useDispatch();
  const selectedContactForm = useSelector(selectedContactFormSelector);

  const { fields, formAgreeEmailContent, formAgreeEmailSubscription, formAgreeSMSContent, formAgreeSMSSubscription } =
    selectedContactForm?.setting?.general || {};

  const handleChange = (key: keyof IParamsApi.IFormSettingGeneral) => (value: any) => {
    dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'general', key, value }));
  };

  const isShowEmail = useMemo(() => {
    return fields?.some((item) => item.type === FormSettingFieldType.Email);
  }, [fields]);
  const isShowSMS = useMemo(() => {
    return fields?.some((item) => item.type === FormSettingFieldType.Phone);
  }, [fields]);

  return (
    <>
      {isShowEmail ? (
        <>
          <Checkbox
            label={'Email Subscription'}
            checked={formAgreeEmailSubscription}
            onChange={() => {
              handleChange('formAgreeEmailSubscription')(!formAgreeEmailSubscription);
            }}
          />
          {formAgreeEmailSubscription ? (
            <TextField
              autoComplete="off"
              label={''}
              value={formAgreeEmailContent}
              onChange={handleChange('formAgreeEmailContent')}
            />
          ) : null}
        </>
      ) : null}
      {isShowSMS ? (
        <>
          <Checkbox
            label={'SMS Subscription'}
            checked={formAgreeSMSSubscription}
            onChange={() => {
              handleChange('formAgreeSMSSubscription')(!formAgreeSMSSubscription);
            }}
          />
          {formAgreeSMSSubscription ? (
            <TextField autoComplete="off" label={''} value={formAgreeSMSContent} onChange={handleChange('formAgreeSMSContent')} />
          ) : null}
        </>
      ) : null}
      {!isShowEmail && !isShowSMS ? (
        <>To enable SMS/Email subscription option, please add 'Phone'/ 'Email' fields to your form</>
      ) : null}
    </>
  );
};

export default ContentSubscription;
