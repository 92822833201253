import { Text, TextProps } from '@shopify/polaris';
import { memo } from 'react';

interface IProps extends Omit<TextProps, 'children' | 'as'> {
  children: React.ReactNode;
}

const RegularText = ({ children, ...props }: IProps) => {
  return (
    <Text as="span" variant="bodySm" {...props}>
      {children}
    </Text>
  );
};
export default memo(RegularText);
