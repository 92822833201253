import { SortDirection } from '@/constants/enum';
import { getSomeDaysAgo } from '@/helpers/time';
import { IResponseApi } from '@/types/apis/response';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export enum ESubscriptionFilter {
  Subscribed = 'subscribed',
  Email = 'email',
  SMS = 'sms',
  NotSubscribed = 'not-subscribed',
}

export interface IFormAnswerParams {
  startDate: Date;
  endDate: Date;
  page: number;
  perPage: number;
  sortDirection: SortDirection;
  formId?: string;
  value?: string;
  subscription?: ESubscriptionFilter;
}

const now = new Date();
// Define the initial state using that type
const initialState: {
  formAnswerParams: IFormAnswerParams;
  formAnswerData?: IResponseApi.IDataFormRecord[];
  titleBtnDatePicker: string;
} = {
  formAnswerParams: {
    startDate: getSomeDaysAgo(30),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    page: 1,
    perPage: 10,
    sortDirection: SortDirection.Desc,
    value: '',
  },
  titleBtnDatePicker: 'Last 30 days',
};

export const formAnswerSlice = createSlice({
  name: 'formAnswer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleSetFormAnswerParams: (state, action: PayloadAction<IFormAnswerParams>) => {
      state.formAnswerParams = action.payload;
    },
    handleSetFormAnswerData: (state, action: PayloadAction<IResponseApi.IDataFormRecord[] | undefined>) => {
      state.formAnswerData = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type

export const formAnswerParamsSelector = createSelector(
  (state: RootState) => state.formAnswer,
  (state) => state.formAnswerParams,
);
export const formAnswerDataSelector = createSelector(
  (state: RootState) => state.formAnswer,
  (state) => state.formAnswerData,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.formAnswer,
  (state) => state.titleBtnDatePicker,
);

export default formAnswerSlice;
