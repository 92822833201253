import { css } from 'styled-components';

export const ModalEditContactFormStyled = css`
  #modal-create-edit-form {
    .Polaris-Layout__Section.Polaris-Layout__Section--oneThird {
      height: 100%;
      max-height: none;
      .section-left {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        gap: 1.25rem;
        position: sticky;
        top: 0;
        height: 100%;
        .nav-icon {
          cursor: pointer;
          padding: 0.25rem 0;
          border-radius: 0.5rem;
          &:hover,
          &.nav-icon__active {
            background: rgba(224, 224, 224, 0.8);
          }
        }
        @media (max-width: 768px) {
          border-bottom: 1px solid #e3e3e3;
        }
      }

      .section-right {
        width: 100%;
        border: 1px solid #e3e3e3;
        border-top: none;
        @media (min-width: 768px) {
          border-bottom: none;
        }
        flex: 1;
        .Polaris-Button__Content {
          justify-content: flex-start;
          width: 100%;
          > .Polaris-Button__Text {
            width: 100%;
          }
        }
      }
    }
    @media (min-width: 768px) {
      .Polaris-Layout__Section:not(.Polaris-Layout__Section--oneThird) {
        margin-left: 0;
      }
    }
    .section-header {
      width: 100%;
      padding: 1.25rem;
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      max-height: 3.125rem;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 999;
      .section-title {
        font-size: 1.25rem;
        &.section-title__bold {
          font-weight: 700;
        }
      }
    }

    @media (min-width: 768px) {
      .section-content {
        height: 570px;
        overflow: auto;
      }
    }
    .drag-item {
      display: flex;
      justify-content: space-between;
      gap: 0.25rem;
      padding: 0.5rem 0.25rem 0.5rem 1.75rem;
      cursor: pointer;
      position: relative;

      .drag-icon {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }

      .drag-item__left {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
      .drag-item__right {
        display: none;
      }

      &:hover {
        background-color: rgba(217, 217, 217, 0.24);
        .drag-icon {
          display: flex;
          align-items: center;
          cursor: grab;
        }
        .drag-item__right {
          display: flex;
          align-items: center;
        }
      }
    }

    .Polaris-Tabs__Outer {
      background: rgba(181, 181, 181, 0.31);
      border-radius: 0.5rem;
      > .Polaris-Box {
        padding: 0;
      }
      .Polaris-Tabs--fitted {
        padding: 0.25rem;
      }
      .Polaris-Tabs__Tab--active {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.625rem 1.25rem;
    }

    .appearance-label {
      min-width: 78px;
    }
    .settings-collapsible-container {
      border-bottom: 1px solid #c4c4c480;
      .Polaris-Button {
        border-radius: 0;
        > span {
          width: 100%;
        }
      }

      .collapsible-content {
        padding: 6px 12px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
    .setting-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
    }

    .time-input-wrapper {
      .Polaris-Connected__Item.Polaris-Connected__Item--primary {
        max-width: 80px;
      }
    }
  }
`;
