import AutoCompleteWithTag from '@/components/AutoCompleteWithTag';
import BoldSmallText from '@/components/BoldSmallText';
import { FormSettingDevice, FormType } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { apiCaller, locationApiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import editContactFormSlice, { contactFormErrorSelector, selectedContactFormSelector } from '@/redux/slice/editContactForm.slice';
import { IParamsApi } from '@/types/apis/params';
import { Button, Checkbox, Collapsible, Icon, Label, Select } from '@shopify/polaris';
import { ChevronDownIcon, ChevronRightIcon } from '@shopify/polaris-icons';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSkip = useSelector(isSkipApiSelector);
  const selectedContactForm = useSelector(selectedContactFormSelector);
  const error = useSelector(contactFormErrorSelector);

  const { type, buttonGroups, isTriggerBeforeChatSession, isDataSync, setting } = selectedContactForm;
  const { isNotShowSpecificLocations, isShowSpecificLocations, notShowSpecificLocations, settingDevice, showSpecificLocations } =
    setting?.targeting || {};

  const [isOpen, setIsOpen] = useState({
    general: false,
    targeting: false,
  });

  const { data: widgets } = apiCaller.useListGroupButtonQuery(
    {
      page: 1,
      perPage: 10,
    },
    { skip: isSkip },
  );
  const { data: countries } = locationApiCaller.useGetCountriesQuery({
    perPage: -1,
  });

  const widgetOptions = useMemo(() => {
    return widgets?.data?.map((widget) => ({ label: widget.name, value: widget.id + '' })) || [];
  }, [widgets?.data]);

  const selectedWidgetOptions = useMemo(() => {
    return buttonGroups?.map((item) => item.id + '') || [];
  }, [buttonGroups]);

  const selectedShowSpecificLocations = useMemo(() => {
    return showSpecificLocations ? showSpecificLocations.split(',') : [];
  }, [showSpecificLocations]);

  const selectedNotShowSpecificLocations = useMemo(() => {
    return notShowSpecificLocations ? notShowSpecificLocations.split(',') : [];
  }, [notShowSpecificLocations]);

  const countryOptions = useMemo(() => {
    return countries?.data.result.map((item) => ({ label: item.name, value: item.isoCode })) || [];
  }, [countries?.data.result]);

  const showSpecificLocationsOptions = useMemo(() => {
    return countryOptions.map((item) => ({
      ...item,
      disabled: isNotShowSpecificLocations && selectedNotShowSpecificLocations.includes(item.value),
    }));
  }, [countryOptions, isNotShowSpecificLocations, selectedNotShowSpecificLocations]);

  const notShowSpecificLocationsOptions = useMemo(() => {
    return countryOptions.map((item) => ({
      ...item,
      disabled: isShowSpecificLocations && selectedShowSpecificLocations.includes(item.value),
    }));
  }, [countryOptions, isShowSpecificLocations, selectedShowSpecificLocations]);

  const handleToggle = (key: keyof typeof isOpen) => () => {
    setIsOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleChangeGeneral = (key: keyof Omit<IParamsApi.ICreateForm, 'setting'>) => (value: any) => {
    dispatch(editContactFormSlice.actions.handleSetSelectedContactForm({ ...selectedContactForm, [key]: value }));
  };

  const handleChangeTargeting = (key: keyof IParamsApi.IFormSettingTargeting) => (value: any) => {
    dispatch(editContactFormSlice.actions.handleChangeSetting({ settingType: 'targeting', key, value }));
  };

  const handleToggleSettingDevice = (device: FormSettingDevice) => (checked: boolean) => {
    handleChangeTargeting('settingDevice')(
      checked ? [...(settingDevice || []), device] : settingDevice?.filter((item) => item !== device),
    );
  };

  useEffect(() => {
    dispatch(
      editContactFormSlice.actions.handleSetError({
        key: 'showSpecificLocations',
        value: isShowSpecificLocations && !selectedShowSpecificLocations.length ? 'Please select at least one location' : '',
      }),
    );
  }, [dispatch, isShowSpecificLocations, selectedShowSpecificLocations.length]);

  useEffect(() => {
    dispatch(
      editContactFormSlice.actions.handleSetError({
        key: 'notShowSpecificLocations',
        value:
          isNotShowSpecificLocations && !selectedNotShowSpecificLocations.length ? 'Please select at least one location' : '',
      }),
    );
  }, [dispatch, isNotShowSpecificLocations, selectedNotShowSpecificLocations.length]);

  return (
    <>
      <div className="settings-collapsible-container">
        <Button fullWidth variant="tertiary" onClick={handleToggle('general')}>
          {
            (
              <div className="setting-tab">
                General
                <Icon source={isOpen.general ? ChevronDownIcon : ChevronRightIcon} />
              </div>
            ) as any
          }
        </Button>
        <Collapsible id="general" open={isOpen.general} transition={{ duration: '500ms' }}>
          <div className="collapsible-content">
            <Select
              label={<BoldSmallText>Form type</BoldSmallText>}
              options={[{ label: 'Button', value: FormType.Button }]}
              onChange={handleChangeGeneral('type')}
              value={type}
            />
            <AutoCompleteWithTag
              label={<BoldSmallText>Chat widget</BoldSmallText>}
              allowMultiple
              options={widgetOptions}
              selectedOptions={selectedWidgetOptions}
              setSelectedOptions={(selected) =>
                handleChangeGeneral('buttonGroups')(
                  widgetOptions
                    .filter((item) => selected.includes(item.value))
                    .map((item) => ({
                      id: +item.value,
                      name: item.label,
                    })),
                )
              }
            />
            <div>
              Navigate to{' '}
              <Button
                variant="plain"
                onClick={() => {
                  navigate(PATH.WIDGETS);
                }}
              >
                chat widget settings
              </Button>
            </div>
            <div>
              <Label id="isTriggerBeforeChatSession">
                <BoldSmallText>Trigger condition</BoldSmallText>
              </Label>
              <Checkbox
                label="Before chat session"
                id="isTriggerBeforeChatSession"
                checked={isTriggerBeforeChatSession}
                onChange={handleChangeGeneral('isTriggerBeforeChatSession')}
              />
            </div>
            <div>
              <Label id="isDataSync">
                <BoldSmallText>Data Sync</BoldSmallText>
              </Label>
              <Checkbox
                label="Sync data with Shopify"
                id="isDataSync"
                checked={isDataSync}
                onChange={handleChangeGeneral('isDataSync')}
              />
            </div>
          </div>
        </Collapsible>
      </div>
      <div className="settings-collapsible-container">
        <Button fullWidth variant="tertiary" onClick={handleToggle('targeting')}>
          {
            (
              <div className="setting-tab">
                Targeting
                <Icon source={isOpen.targeting ? ChevronDownIcon : ChevronRightIcon} />
              </div>
            ) as any
          }
        </Button>
        <Collapsible id="targeting" open={isOpen.targeting} transition={{ duration: '500ms' }}>
          <div className="collapsible-content">
            <BoldSmallText>Devices</BoldSmallText>
            <div className="d-flex gap-16">
              <Checkbox
                label="Desktop"
                id="desktop"
                checked={settingDevice?.includes(FormSettingDevice.Desktop)}
                onChange={handleToggleSettingDevice(FormSettingDevice.Desktop)}
              />
              <Checkbox
                label="Mobile"
                id="mobile"
                checked={settingDevice?.includes(FormSettingDevice.Mobile)}
                onChange={handleToggleSettingDevice(FormSettingDevice.Mobile)}
              />
            </div>

            <BoldSmallText>Location</BoldSmallText>
            <Checkbox
              label="Show on specific locations"
              id="isShowSpecificLocations"
              checked={isShowSpecificLocations}
              onChange={(checked) => {
                handleChangeTargeting('isShowSpecificLocations')(checked);
                if (checked && showSpecificLocations && isNotShowSpecificLocations) {
                  handleChangeTargeting('showSpecificLocations')(
                    selectedShowSpecificLocations.filter((item) => !selectedNotShowSpecificLocations.includes(item)).join(','),
                  );
                }
              }}
            />
            <Collapsible id="showSpecificLocations" open={!!isShowSpecificLocations} transition={{ duration: '500ms' }}>
              <AutoCompleteWithTag
                label={''}
                labelHidden
                allowMultiple
                options={showSpecificLocationsOptions}
                selectedOptions={selectedShowSpecificLocations}
                setSelectedOptions={(selected) => {
                  handleChangeTargeting('showSpecificLocations')(selected.join(','));
                }}
                error={error.showSpecificLocations}
              />
            </Collapsible>
            <Checkbox
              label="Don’t show on specific locations"
              id="isNotShowSpecificLocations"
              checked={isNotShowSpecificLocations}
              onChange={(checked) => {
                handleChangeTargeting('isNotShowSpecificLocations')(checked);
                if (checked && notShowSpecificLocations && isShowSpecificLocations) {
                  handleChangeTargeting('notShowSpecificLocations')(
                    selectedNotShowSpecificLocations.filter((item) => !selectedShowSpecificLocations.includes(item)).join(','),
                  );
                }
              }}
            />
            <Collapsible id="showSpecificLocations" open={!!isNotShowSpecificLocations} transition={{ duration: '500ms' }}>
              <AutoCompleteWithTag
                label={''}
                labelHidden
                allowMultiple
                options={notShowSpecificLocationsOptions}
                selectedOptions={selectedNotShowSpecificLocations}
                setSelectedOptions={(selected) => {
                  handleChangeTargeting('notShowSpecificLocations')(selected.join(','));
                }}
                error={error.notShowSpecificLocations}
              />
            </Collapsible>
          </div>
        </Collapsible>
      </div>
    </>
  );
};

export default Settings;
