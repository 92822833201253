import authSlice, { isSkipApiSelector } from '@/redux/slice/auth.slice';
import RenderRouter from '@/routes';
import { createApp } from '@shopify/app-bridge';
import { NavMenu } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge/utilities';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from './config';
import { PATH } from './constants/path';

const navigationLinks: Array<{
  label: string;
  destination: string;
  url: {
    pathname: string;
    search: string;
  };
}> = [
  {
    label: 'Chat widget',
    destination: PATH.WIDGETS.pathname,
    url: PATH.WIDGETS,
  },
  {
    label: 'Analytics',
    destination: PATH.ANALYTICS.pathname,
    url: PATH.ANALYTICS,
  },
  {
    label: 'Contact form',
    destination: PATH.CONTACT_FORM.pathname,
    url: PATH.CONTACT_FORM,
  },
  {
    label: 'Form answer',
    destination: PATH.FORM_ANSWER.pathname,
    url: PATH.FORM_ANSWER,
  },
  {
    label: 'Pricing plan',
    destination: PATH.PRICING_PLAN.pathname,
    url: PATH.PRICING_PLAN,
  },
];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isSkip = useSelector(isSkipApiSelector);
  const dispatch = useDispatch();
  const configApp = {
    apiKey: process.env.REACT_APP_API_KEY || '',
    host: new URLSearchParams(location.search).get('host') || '',
    forceRedirect: process.env.REACT_APP_MODE === 'live',
  };

  useEffect(() => {
    if (config && config.role !== 'admin' && config.embedded === '1') {
      const timer = setTimeout(() => {
        var crisp = document.createElement('script');
        crisp.setAttribute('src', `${process.env.REACT_APP_API_END_POINT}public/script/plugin.js?shop=${config.shop}`);
        crisp.setAttribute('id', 'omega-chat-crisp');
        crisp.appendChild(document.createTextNode(``));
        document.body.appendChild(crisp);
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSkip) {
    const getToken = async () => {
      try {
        dispatch(authSlice.actions.handleLoading(true));
        const app = createApp(configApp);
        const sessionToken = await getSessionToken(app);
        dispatch(
          authSlice.actions.handleToken({
            token: sessionToken,
            expiredTime: Date.now() + 60 * 1000,
            isLoading: false,
          }),
        );
      } catch (err) {
        dispatch(authSlice.actions.handleLoading(false));
      }
    };
    getToken();
  }

  return (
    <AppProvider i18n={enTranslations}>
      {config.embedded === '1' ? (
        <>
          <NavMenu>
            <a
              href={PATH.DEFAULT.pathname}
              rel="home"
              onClick={(e) => {
                e.preventDefault();
                navigate(PATH.DEFAULT);
              }}
            >
              Home
            </a>
            {navigationLinks.map((item) => {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  id={item.destination}
                  key={item.destination}
                  href={item.destination}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(item.url);
                  }}
                >
                  {item.label}
                </a>
              );
            })}
          </NavMenu>
          <RenderRouter />
        </>
      ) : (
        <RenderRouter />
      )}
    </AppProvider>
  );
}

export default App;
