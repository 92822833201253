import { Button, DropZone, Icon, Image, Text } from '@shopify/polaris';
import { AlertCircleIcon, UploadIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { DefaultUploadImageStyled, StyledUploadedFiles } from './styled';
import { useSelector } from 'react-redux';
import { customizeSelected } from '@/redux/slice/widget.slice';
import RegularText from '../RegularText';
import { fileToBase64 } from '@/helpers/file';
import { IUpload } from '@/types/components/upload';

interface IProps {
  setFile: (value: IUpload) => void;
  url?: string;
  label?: React.ReactNode;
  labelHidden?: boolean;
  variant?: 'default' | 'primary';
  limitSize?: number;
}
const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

function UploadImage({ setFile, url, label, labelHidden, variant = 'primary', limitSize = 100000 }: IProps) {
  const customize = useSelector(customizeSelected);
  const [isShowErrorInline, setIsShowErrorInline] = useState({ status: false, msg: '' });
  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      const uploadedFile = acceptedFiles[0];
      if (uploadedFile) {
        fileToBase64(uploadedFile).then((res) => {
          setFile({
            file: res as string,
            url: window.URL.createObjectURL(uploadedFile),
          });
        });
      }
    },
    [setFile],
  );

  const validateFile = useCallback(
    (file: any) => {
      setIsShowErrorInline({ status: false, msg: '' });
      if (file.size > limitSize) {
        setIsShowErrorInline({ status: true, msg: 'File too large' });
        return false;
      } else if (!validImageTypes.includes(file.type)) {
        setIsShowErrorInline({ status: true, msg: 'Unsupported format' });
        return false;
      } else {
        setIsShowErrorInline({ status: false, msg: '' });
      }
      return true;
    },
    [limitSize],
  );

  const { dropZone, helpText, error } = useMemo(() => {
    const helpText = `Supported formats: JPG, JPEG, GIF, and PNG. Maximum ${
      limitSize > 1000000 ? Math.round(limitSize / 1000000) + 'MB' : Math.round(limitSize / 1000) + 'KB'
    }`;
    return {
      dropZone: (
        <DropZone
          customValidator={validateFile}
          type="image"
          accept={validImageTypes.toString()}
          label={label || 'Custom image'}
          labelHidden={labelHidden}
          onDrop={handleDropZoneDrop}
          outline={!url}
          variableHeight
        >
          {variant === 'primary' ? (
            <div className="upload-container">{url ? <img alt="" src={url} /> : <Icon source={UploadIcon} />}</div>
          ) : url ? (
            <Image alt="image" style={{ borderRadius: '0.5rem', width: '100%', height: '100%' }} source={url} />
          ) : (
            <div className="upload-container">
              <Button>Add files</Button>
              <RegularText>{helpText}</RegularText>
            </div>
          )}
        </DropZone>
      ),
      helpText: (
        <div className="mt-8">
          <RegularText>{helpText}</RegularText>
        </div>
      ),
      error: isShowErrorInline.status ? (
        <div className="mt-8 d-flex provide-evidence">
          <Icon source={AlertCircleIcon} tone="critical" />
          <Text as="span" variant="bodyLg" tone="critical">
            {isShowErrorInline.msg}
          </Text>
        </div>
      ) : null,
    };
  }, [
    handleDropZoneDrop,
    isShowErrorInline.msg,
    isShowErrorInline.status,
    label,
    labelHidden,
    limitSize,
    url,
    validateFile,
    variant,
  ]);

  return (
    <>
      {variant === 'primary' ? (
        <StyledUploadedFiles borderRadius={customize.buttonStyle}>
          {dropZone}
          {helpText}
          {error}
        </StyledUploadedFiles>
      ) : (
        <DefaultUploadImageStyled>
          {dropZone}
          {error}
        </DefaultUploadImageStyled>
      )}
    </>
  );
}

export default memo(UploadImage);
