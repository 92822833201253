import styled from 'styled-components';

export const ContactFormPageStyled = styled.div`
  .Polaris-ShadowBevel,
  .Polaris-Box {
    overflow: hidden;
  }
  .Polaris-Icon {
    margin: 0;
  }
  .button-container {
    display: flex;
    justify-content: end;
    margin-bottom: 24px;
  }
  .Polaris-IndexTable {
    .Polaris-BulkActions__BulkActionButton {
      &:last-child > button {
        padding: 6px 4px;
      }
      & > button {
        padding: 6px 12px;
        .Polaris-Icon {
          margin: 0;
          height: 16px;
        }
        & > .Polaris-Button__Icon {
          display: none;
        }
      }
    }
    .Polaris-ActionList__Prefix {
      .Polaris-Icon {
        margin: 0;
      }
    }
    .Polaris-IndexTable__TableRow {
      cursor: default;
    }
    .Polaris-Checkbox__Input {
      cursor: pointer;
    }
  }
`;
